/**
 *
 *
 *
 */
import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import Spinner from '../../_components/Spinner';
import { genReport } from '../gen.report';
import * as Store from '../store';
import * as u from '../../utils';

/**
 *
 *
 *
 */
const COPY = [
  `Please review the report below carefully to ensure all the submission details are correct before proceeding.`,
  `Once confirmed, kindly print this submission form and include it with the samples to complete the process.`,
];


/**
 *
 *
 *
 */
export default function SubmissionInfo() {

  const { id } = Router.useParams();
  const currRef = React.useRef(null);
  const [ isWait, setIsWait ] = React.useState(false);
  const { state, actions } = React.useContext(Store.Context);
  React.useEffect(() => { onGenReport(); }, [currRef.current]);
  const currActiveItem = onCurrActiveItem();
  const $isSubmission = currActiveItem?.status === 'COMPLETE';

  return (
    <S.Container>
      <S.TopContainer>
        <S.Description>
          {COPY.map((e, i) => <T.IX4 key={i}>{e}</T.IX4>)}
        </S.Description>
        <S.ButtonContainer $isSubmission={$isSubmission} onClick={onConfirm}>
          {isWait ? <Spinner $bg={'#5c940d'} /> : <S.IL6>{$isSubmission ? 'Submitted' : 'Confirm & Submit'}</S.IL6>}
        </S.ButtonContainer>
      </S.TopContainer>
      <S.FrameContainer>
        <iframe {...onFrameProps()} />
      </S.FrameContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onConfirm() {
    if (isWait) return;
    setIsWait(true);
    await u.sleep(1000);
    await actions.onAppConfirmSubmission({ id });
    return setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onFrameProps() {
    return {
      width: '100%',
      height: '100%',
      style: { border: '1px solid #ccc', borderRadius: '6px' },
      title: 'PDF Preview',
      ref: currRef,
    };
  }

  /**
   *
   *
   *
   */
  async function onGenReport() {
    const doc = await genReport(currActiveItem);
    const blob = doc.output('blob');
    const url = URL.createObjectURL(blob);
    currRef.current.src = url;
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(id);
    return state.submissions.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  height: 100%;
`;

S.TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d0d7de;
  padding-top: 5px;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

S.Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  gap: 5px;
`;

S.ButtonContainer = styled.div`
  height: 40px;
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #5c940d;
  border-radius: 4px;
  padding: 0px 20px;
  background: rgba(130, 201, 30, 0.1);
  &:hover { cursor: pointer; background: rgba(130, 201, 30, 0.3); }
  ${p => p.$isSubmission && `
    background: ${rgba('#d0d7de', 0.8)};
    border: 1px solid #d0d7de;
    p { color: #999; }
    &:hover { cursor: not-allowed; background: ${rgba('#d0d7de', 0.8)}; }
  `}
`;

S.FrameContainer = styled.div`
  height: calc(100% - 100px);
`;

S.IL6 = styled(T.IL6)`
  color: #5c940d;
  text-align: center;
`;
