/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import SubmissionSteps from '../components/Submission.Steps';
import SubmissionInfo from '../components/Submission.Info';
import SubmissionSamples from '../components/Submission.Samples';
import SubmissionAnimale from '../components/Submission.Animale';
import SubmissionResult from '../components/Submission.Result';
import SubmissionBanner from '../components/Submission.Banner';
import Menu from '../components/Menu';


/**
 *
 *
 *
 */
export default function Submission() {

  const nav = Router.useNavigate();
  const { id, step } = Router.useParams();

  return (
    <S.Container>
      <Menu />
      <S.InnerContainer>
        <S.HeaderContainer>
          <SubmissionSteps />
          {/* <SubmissionPrice /> */}
        </S.HeaderContainer>
        <SubmissionBanner />
        <S.InfoContainer>
          {step == null && <SubmissionAnimale />}
          {step === '0' && <SubmissionAnimale />}
          {step === '1' && <SubmissionSamples />}
          {step === '2' && <SubmissionInfo />}
          {step === '3' && <SubmissionResult />}
        </S.InfoContainer>
      </S.InnerContainer>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  height: calc(100% - 15px);
  display: flex;
  margin-top: 15px;
  @media (max-width: 1200px) {
    padding: 0px 10px 50px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 100%;
`;

S.HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

S.InfoContainer = styled.div`
  width: 100%;
  height: calc(100% - 200px);
  margin-top: 15px;
`;
