/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { rgba } from 'polished';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import OmniSearch from '../../_components/Omni.Search';
import AnimalsItem from '../components/Animals.Item';
import Spinner from '../../_components/Spinner';
import Table from '../../_components/Table';
import * as I from '../../_components/Icons';
import Menu from '../components/Menu';
import * as Store from '../store';
import * as u from '../../utils';



/**
 *
 *
 *
 */
export default function Animals() {

  const { pathname } = Router.useLocation();
  const [ isWait, setIsWait ] = React.useState(false);
  const { state, actions } = React.useContext(Store.Context);
  const $isActive = pathname === '/animals';

  return (
    <S.Container>
      <Menu />
      <S.InnerContainer>
        <S.HeaderContainer>
          <OmniSearch />
          <S.CreateContainer $bg={'#5c940d'} onClick={onCreateAnimal}>
            {isWait ? <Spinner $bg={'#F00'} /> : (
              <React.Fragment>
                <T.IL6 $c={'#5c940d'}>New Animal</T.IL6>
                <I.Plus style={{ transform: 'scale(1.2)' }} stroke={'#5c940d'} />
              </React.Fragment>
            )}
          </S.CreateContainer>
        </S.HeaderContainer>
        <Table { ...onTableProps()} />
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onCreateAnimal() {
    if (isWait) return;
    setIsWait(true);
    await u.sleep(500);
    await actions.onAppCreateAnimal();
    setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onTableProps() {
    return {
      subFunc: onSubFunc,
      $theme: 'rgb(239, 244, 231)',
      data: state.animals?.map(a => ({
        id: a.id,
        reference: a.reference ?? '-',
        name: a.name ?? '-',
        specie: a.specie?.name ?? '-',
        breed: a.breed?.name ?? '-',
        owner: a.owner ?? '-',
        age: u.onAge(a.dob),
        dob: a.dob ? DateTime.fromISO(a.dob).toFormat('dd-MM-yyyy') : '-',
        gender: a.gender ?? '-',
      })),
    };
  }

  /**
   *
   *
   *
   */
  function onSubFunc(row) {

    const $isLast = R.equals(row.id, R.last(state?.animals)?.id);
    const onSame = e => Number(e.id) === Number(row.id);
    const curr = state?.animals?.find(onSame);

    return (
      <S.SubTableContainer key={row.id} $isLast={$isLast}>
        <AnimalsItem {...curr} />
      </S.SubTableContainer>
    );
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  margin-top: 15px;
  @media (max-width: 1200px) {
    padding: 0px 10px 50px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
`;

S.HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  width: 100%;
  margin-bottom: 10px;
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 6px;
  border: 1px solid ${p => p.$bg};
  background: ${p => rgba(p.$bg, 0.1)};
  min-width: 200px;
  height: 100%;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
    background: ${p => rgba(p.$bg, 0.8)};
    p { color: #fff; }
    svg { stroke: #fff; }
  }
`;

S.SubTableContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-top: 1px solid #D0D7DE;
  ${p => !p.$isLast && `border-bottom: 1px solid #D0D7DE;`}
`;
