/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import * as Store from '../store';


/**
 *
 *
 *
 */
const STEPS = [
  { $key: 'animal',  $s: 8, description: 'Animal Information', time: '1 min',   onValidate: s => !!s?.animal_id           },
  { $key: 'samples', $s: 9, description: 'Sample Information', time: '2 min',   onValidate: s => !!s?.samples?.length > 0 },
  { $key: 'form',    $s: 7, description: 'Review & Submit',    time: '1 min',   onValidate: s => s?.status === 'COMPLETE' },
  { $key: 'results', $s: 7, description: 'Results',            time: '~2 days', onValidate: s => s?.status === 'FINISHED' },
];


/**
 *
 *
 *
 */
export default function SubmissionBanner() {

  const nav = Router.useNavigate();
  const { id: submission_id, step } = Router.useParams();
  const { state } = React.useContext(Store.Context);
  const currActiveItem = onCurrActiveItem();
  const BASE = `/submission/${submission_id}`;
  const [ prev, curr, next ] = onCurrActiveStep();

  return (
    <S.Container>
      <S.InnerContainer $jc={'flex-start'}>
        {onPrevComponent()}
      </S.InnerContainer>
      <S.InnerContainer $jc={'center'}>
        <T.IH6 $c={'#862e9c'}>
          {curr.description}
        </T.IH6>
      </S.InnerContainer>
      <S.InnerContainer $jc={'flex-end'}>
        {onNextComponent()}
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onPrevComponent() {

    if (!prev) return null;
    const prevStep = Math.max(Number(step) - 1, 0);
    const onClick = () => nav(`${BASE}/${prevStep}`);

    return (
      <S.SideButton onClick={onClick} $isValid={true}>
        <S.IconContainer>
          <I.FigmaArrow stroke={'#862e9c'} style={{ transform: 'scale(1.4)' }} />
        </S.IconContainer>
        <T.IX4 $c={'#862e9c'}>{prev.description}</T.IX4>
      </S.SideButton>
    );
  }

  /**
   *
   *
   *
   */
  function onNextComponent() {

    if (!next) return null;
    const nextStep = Math.min(Number(step) + 1, 3);
    const $isValid = curr.onValidate(currActiveItem);
    const onClick = () => $isValid && nav(`${BASE}/${nextStep}`);

    return (
      <S.SideButton onClick={onClick} $isValid={$isValid}>
        <T.IX4 $c={'#862e9c'}>{next.description}</T.IX4>
        <S.IconContainer>
          <I.FigmaArrow stroke={'#862e9c'} style={{ transform: 'rotate(180deg) scale(1.4)' }} />
        </S.IconContainer>
      </S.SideButton>
    );
  }

  /**
   *
   *
   *
   */
  function onCurrActiveStep() {
    const onSameC = (_, idx) => idx === Number(step);
    const onSameP = (_, idx) => idx === Number(step) - 1;
    const onSameN = (_, idx) => idx === Number(step) + 1;
    const curr = STEPS.find(onSameC);
    const prev = STEPS.find(onSameP);
    const next = STEPS.find(onSameN);
    return [ prev, curr, next ];
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(submission_id);
    return state.submissions?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  height: 45px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
`;

S.InnerContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${p => p.$jc};
  align-items: center;
  gap: 10px;
`;

S.SideButton = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0px 15px;
  border-radius: 4px;
  border: 1px solid ${p => p.$isValid ? '#862e9c' : '#d0d7de'};
  &:hover { cursor: pointer; }
  background: ${p => p.$isValid ? 'rgba(134, 46, 156, 0.1)' : '#f6f8fa'};
  ${p => !p.$isValid && `
    &:hover { cursor: not-allowed; }
    p { color: grey; }
    svg { stroke: grey; }
  `}
`;

S.IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg { stroke-width: 1.5px; }
`;
