/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import * as A from '../../_components/Animals';
import OmniSearch from '../../_components/Omni.Search';
import T from '../../_components/Typography';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
const COLUMNS = [
  [
    { $label: 'Name',    $key: 'name'                                              },
    { $label: 'Owner',   $key: 'owner'                                             },
    { $label: 'Species', $key: 'specie.name', onKey: e => e['specie']?.name ?? '-' },
  ],
  [
    { $label: 'Breed',   $key: 'breed.name',  onKey: e => e['breed']?.name ?? '-'  },
    { $label: 'Age',     $key: 'age',         onKey: e => u.onAge(e['dob'])        },
    { $label: 'Gender',  $key: 'gender',      onKey: e => e['gender'] ?? '-'       },
  ]
];


/**
 *
 *
 *
 */
export default function SubmissionAnimaleLista() {

  const { id: submission_id } = Router.useParams();
  const nav = Router.useNavigate();
  const [ isWait, setIsWait ] = React.useState(false);
  const { state, actions } = React.useContext(Store.Context);
  const currActiveItem = onCurrActiveItem();

  return (
    <S.Container>
      <S.OmniContainer>
        <OmniSearch {...onOmniProps()} />
      </S.OmniContainer>
      {isWait && <S.WaitContainer />}
      <S.ListaContainer>
        {state.animals?.map(onSubComp)}
      </S.ListaContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onOmniProps() {
    return {
      $height: 40,
      placeholder: 'Search existing animals',
    };
  }

  /**
   *
   *
   *
   */
  function onSubComp(elm) {

    const $isActive = (currActiveItem?.animal_id === elm.id);
    const onClick = () => onChooseAnimale(elm.id);
    const $subProps = { $isActive, onClick };

    return (
      <S.SubContainer key={elm.id} {...$subProps}>
        <S.IconContainer $isActive={$isActive}>
          {onActiveIconComp()}
        </S.IconContainer>
        <S.InfoContainer>
          {COLUMNS.map((rows, cIdx) => (
            <S.ColumnContainer key={`col-${cIdx}`}>
              {rows.map(onRowComp)}
            </S.ColumnContainer>
          ))}
        </S.InfoContainer>
      </S.SubContainer>
    );

    /**
     *
     *
     *
     */
    function onActiveIconComp() {
      switch (elm?.specie?.name) {
        case 'Dog': return <A.Dog />;
        case 'Cat': return <A.Cat />;
        case 'Rabbit': return <A.Rabbit />;
        case 'Bird': return <A.Bird />;
        case 'Horse': return <A.Horse />;
        default: return null;
      }
    }

    /**
     *
     *
     *
     */
    function onRowComp(r, rIdx) {
      return (
        <S.RowInfoContainer key={`row-${rIdx}`}>
          <T.IM5>{r.$label}:</T.IM5>
          <S.LineDivider $isActive={$isActive} />
          <T.IM4>{r.onKey ? r.onKey(elm) : elm[r.$key] ?? '-'}</T.IM4>
        </S.RowInfoContainer>
      );
    }
  }

  /**
   *
   *
   *
   */
  async function onChooseAnimale(animal_id) {
    if (isWait) return;
    setIsWait(true);
    const params = { id: submission_id, animal_id };
    await actions.onAppUpdateSubmission(params);
    setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(submission_id);
    return state.submissions?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  flex: 6;
  position: relative;
  padding-left: 15px;
  border-left: 1px solid #d0d7de;
  height: 100%;
`;

S.OmniContainer = styled.div`
  width: 100%;
  margin-bottom: 5px;
`;

S.WaitContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(200, 200, 200, 0.7);
`;

S.IconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 6px;
  background: #f6f8fa;
  border: 1px solid #d0d7de;
  margin-right: 10px;
  ${p => p.$isActive && `
    background: rgba(255, 102, 0, 0.1);
    svg { stroke: rgb(255, 102, 0); }
  `}
`;

S.InfoContainer = styled.div`
  flex: 1;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

S.ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

S.RowInfoContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

S.LineDivider = styled.div`
  flex: 1;
  margin: 0px 10px;
  height: 1px;
  background-color: rgba(134, 46, 156, 0.2);
  ${p => p.$isActive && `background-color: rgb(255, 102, 0);`}
`;

S.ListaContainer = styled.div`
  height: calc(100% - 45px);
  overflow-y: scroll;
`;

S.SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px dashed #d0d7de;
  padding: 10px 0px;
  &:hover {
    cursor: pointer;
    ${S.LineDivider} {
      background-color: #862e9c;
    }
    ${S.IconContainer} {
      background-color: rgba(134, 46, 156, 0.1);
      svg { stroke: #862e9c; }
    }
  }
`;
