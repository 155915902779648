/**
 *
 *
 *
 */
import React from 'react';
import { produce } from 'immer';
import * as u from '../utils';
import * as F from './frgs';


/**
 *
 *
 *
 */
export const init = {
  /**
   *
   *
   *
   */
  init: false,
  /**
   *
   *
   *
   */
  me: null,
  /**
   *
   *
   *
   */
  submissions: [],
  /**
   *
   *
   *
   */
  animals: [],
  /**
   *
   *
   *
   */
  species: [],
};


/**
 *
 *
 *
 */
export const reducer = produce((state = init, action) => {
  switch (action.type) {
    case 'INIT_END': {
      state.init = true;
      return state;
    }
    case 'AUTH_GET_ME': {
      state.me = action.payload;
      return state;
    }
    case 'APP_GET_SPECIES': {
      state.species = action.payload;
      return state;
    }
    case 'APP_GET_SUBMISSIONS': {
      state.submissions = action.payload;
      return state;
    }
    case 'APP_CREATE_SUBMISSION': {
      state.submissions.push(action.payload);
      return state;
    }
    case 'APP_UPDATE_SUBMISSION': {
      const isSame = elm => Number(elm.id) === Number(action.payload.id);
      const idxC = state.submissions.findIndex(isSame);
      const prev = state.submissions[idxC];
      state.submissions[idxC] = { ...prev, ...action.payload };
      return state;
    }
    case 'APP_REMOVE_ANIMAL_FROM_SUBMISSION': {
      const isSame = elm => Number(elm.id) === Number(action.payload.submission_id);
      const idx = state.submissions.findIndex(isSame);
      state.submissions[idx].animal_id = null;
      state.submissions[idx].animal = null;
      return state;
    }
    case 'APP_REMOVE_SUBMISSION': {
      const isSame = elm => Number(elm.id) !== Number(action.payload.id);
      state.submissions = state.submissions.filter(isSame);
      return state;
    }
    case 'APP_GET_ANIMALS': {
      state.animals = action.payload;
      return state;
    }
    case 'APP_CREATE_ANIMAL': {
      state.animals.push(action.payload);
      return state;
    }
    case 'APP_INSERT_ANIMAL': {
      state.animals.unshift(action.payload);
      return state;
    }
    case 'APP_UPDATE_ANIMAL': {
      const isSame = elm => Number(elm.id) === Number(action.payload.id);
      const idxC = state.animals.findIndex(isSame);
      const prev = state.animals[idxC];
      state.animals[idxC] = { ...prev, ...action.payload };
      return state;
    }
    case 'APP_REMOVE_ANIMAL': {
      const isSame = elm => Number(elm.id) !== Number(action.payload.id);
      state.animals = state.animals.filter(isSame);
      return state;
    }
    case 'APP_CREATE_SAMPLE': {
      const isSame = elm => Number(elm.id) === Number(action.payload.submission_id);
      const idx = state.submissions.findIndex(isSame);
      if (!state.submissions[idx].samples) state.submissions[idx].samples = [];
      state.submissions[idx].samples.push(action.payload);
      return state;
    }
    case 'APP_UPDATE_SAMPLE': {
      const isSameA = elm => Number(elm.id) === Number(action.payload.submission_id);
      const isSameB = elm => Number(elm.id) === Number(action.payload.id);
      const idxA = state.submissions.findIndex(isSameA);
      const idxB = state.submissions[idxA].samples.findIndex(isSameB);
      const prev = state.submissions[idxA].samples[idxB];
      state.submissions[idxA].samples[idxB] = { ...prev, ...action.payload };
      return state;
    }
    case 'APP_REMOVE_SAMPLE': {
      const isSameA = elm => Number(elm.id) !== Number(action.payload.submission_id);
      const isSameB = elm => Number(elm.id) !== Number(action.payload.id);
      const idxA = state.submissions.findIndex(isSameA);
      state.submissions[idxA].samples = state.submissions[idxA].samples.filter(isSameB);
      return state;
    }
    case 'APP_CREATE_SAMPLE_MEDIA': {
      const isSameA = elm => Number(elm.id) === Number(action.payload.submission_id);
      const isSameB = elm => Number(elm.id) === Number(action.payload.sample_id);
      const idxA = state.submissions.findIndex(isSameA);
      const idxB = state.submissions[idxA].samples.findIndex(isSameB);
      if (!state.submissions[idxA].samples[idxB]?.media) state.submissions[idxA].samples[idxB].media = [];
      state.submissions[idxA].samples[idxB].media.push(action.payload);
      return state;
    }
    case 'APP_REMOVE_SAMPLE_MEDIA': {
      const isSameA = elm => Number(elm.id) !== Number(action.payload.submission_id);
      const isSameB = elm => Number(elm.id) !== Number(action.payload.sample_id);
      const isSameM = elm => Number(elm.id) !== Number(action.payload.media_id);
      const idxA = state.submissions?.findIndex(isSameA);
      const idxB = state.submissions[idxA]?.samples?.findIndex(isSameB);
      const nexM = state.submissions[idxA]?.samples[idxB]?.media?.filter(isSameM);
      state.submissions[idxA].samples[idxB].media = nexM;
      return state;
    }
    default: {
      return state;
    }
  }
});



/**
 *
 *
 *
 */
export const getActions = (state, dispatch) => {

  /**
   *
   *
   *
   */
  const inner = {};

  /**
   *
   *
   *
   */
  inner.onAuthGetMe = async function onAuthGetMe() {
    const [data, err] = await u.fetcher(F.AUTH_GET_ME);
    if (err) return console.log(`onAuthGetMe:err`, err);
    dispatch({ type: 'AUTH_GET_ME', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppGetSpecies = async function onAppGetSpecies() {
    const [data, err] = await u.fetcher(F.APP_GET_SPECIES);
    if (err) return console.log(`onAppGetSpecies:err`, err);
    dispatch({ type: 'APP_GET_SPECIES', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppGetSubmissions = async function onAppGetSubmissions() {
    const [data, err] = await u.fetcher(F.APP_GET_SUBMISSIONS);
    if (err) return console.log(`onAppGetSubmissions:err`, err);
    dispatch({ type: 'APP_GET_SUBMISSIONS', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppCreateSubmission = async function onAppCreateSubmission() {
    const [data, err] = await u.fetcher(F.APP_CREATE_SUBMISSION);
    if (err) return console.log(`onAppCreateSubmission:err`, err);
    dispatch({ type: 'APP_CREATE_SUBMISSION', payload: data });
    return data;
  };

  /**
   *
   *
   *
   */
  inner.onAppUpdateSubmission = async function onAppUpdateSubmission(params) {
    const [data, err] = await u.fetcher(F.APP_UPDATE_SUBMISSION, params);
    if (err) return console.log(`onAppUpdateSubmission:err`, err);
    dispatch({ type: 'APP_UPDATE_SUBMISSION', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppRemoveAnimalFromSubmission = async function onAppRemoveAnimalFromSubmission(params) {
    const [_, err] = await u.fetcher(F.APP_REMOVE_ANIMAL_FROM_SUBMISSION, params);
    if (err) return console.log(`onAppRemoveAnimalFromSubmission:err`, err);
    dispatch({ type: 'APP_REMOVE_ANIMAL_FROM_SUBMISSION', payload: params });
  };

  /**
   *
   *
   *
   */
  inner.onAppRemoveSubmission = async function onAppRemoveSubmission(params) {
    const [_, err] = await u.fetcher(F.APP_REMOVE_SUBMISSION, params);
    if (err) return console.log(`onAppRemoveSubmission:err`, err);
    dispatch({ type: 'APP_REMOVE_SUBMISSION', payload: params });
  };

  /**
   *
   *
   *
   */
  inner.onAppConfirmSubmission = async function onAppConfirmSubmission(params) {
    const [data, err] = await u.fetcher(F.APP_CONFIRM_SUBMISSION, params);
    if (err) return console.log(`onAppConfirmSubmission:err`, err);
    dispatch({ type: 'APP_CONFIRM_SUBMISSION', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppGetAnimals = async function onAppGetAnimals() {
    const [data, err] = await u.fetcher(F.APP_GET_ANIMALS);
    if (err) return console.log(`onAppGetAnimals:err`, err);
    dispatch({ type: 'APP_GET_ANIMALS', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppCreateAnimal = async function onAppCreateAnimal() {
    const [data, err] = await u.fetcher(F.APP_CREATE_ANIMAL);
    if (err) return console.log(`onAppCreateAnimal:err`, err);
    dispatch({ type: 'APP_CREATE_ANIMAL', payload: data });
    return data;
  };

  /**
   *
   *
   *
   */
  inner.onAppInsertAnimal = async function onAppInsertAnimal(params) {
    const [data, err] = await u.fetcher(F.APP_INSERT_ANIMAL, params);
    if (err) return console.log(`onAppInsertAnimal:err`, err);
    dispatch({ type: 'APP_INSERT_ANIMAL', payload: data });
    return data;
  };

  /**
   *
   *
   *
   */
  inner.onAppUpdateAnimal = async function onAppUpdateAnimal(payload) {
    const [data, err] = await u.fetcher(F.APP_UPDATE_ANIMAL, payload);
    if (err) return console.log(`onAppUpdateAnimal:err`, err);
    dispatch({ type: 'APP_UPDATE_ANIMAL', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppRemoveAnimal = async function onAppRemoveAnimal(payload) {
    const [data, err] = await u.fetcher(F.APP_REMOVE_ANIMAL, payload);
    if (err) return console.log(`onAppRemoveAnimal:err`, err);
    dispatch({ type: 'APP_REMOVE_ANIMAL', payload: payload });
  };

  /**
   *
   *
   *
   */
  inner.onAppCreateSample = async function onAppCreateSample(params) {
    const [data, err] = await u.fetcher(F.APP_CREATE_SAMPLE, params);
    if (err) return console.log(`onAppCreateSample:err`, err);
    dispatch({ type: 'APP_CREATE_SAMPLE', payload: data });
    return data;
  };

  /**
   *
   *
   *
   */
  inner.onAppUpdateSample = async function onAppUpdateSample(params) {
    const [data, err] = await u.fetcher(F.APP_UPDATE_SAMPLE, params);
    if (err) return console.log(`onAppUpdateSample:err`, err);
    dispatch({ type: 'APP_UPDATE_SAMPLE', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppRemoveSample = async function onAppRemoveSample(params) {
    const [_, err] = await u.fetcher(F.APP_REMOVE_SAMPLE, params);
    if (err) return console.log(`onAppRemoveSample:err`, err);
    dispatch({ type: 'APP_REMOVE_SAMPLE', payload: params });
  };

  /**
   *
   *
   *
   */
  inner.onAppCreateSampleMedia = async function onAppCreateSampleMedia(params) {
    const [data, err] = await u.fetcher(F.APP_CREATE_SAMPLE_MEDIA, params);
    if (err) return console.log(`onAppCreateSampleMedia:err`, err);
    const payload = { ...params, ...data };
    dispatch({ type: 'APP_CREATE_SAMPLE_MEDIA', payload });
    return payload;
  };

  /**
   *
   *
   *
   */
  inner.onAppRemoveSampleMedia = async function onAppRemoveSampleMedia(params) {
    const [_, err] = await u.fetcher(F.APP_REMOVE_SAMPLE_MEDIA, { id: params.media_id });
    if (err) return console.log(`onAppRemoveSampleMedia:err`, err);
    dispatch({ type: 'APP_REMOVE_SAMPLE_MEDIA', payload: params });
  };

  /**
   *
   *
   *
   */
  inner.onCdnGeneratePresignUrl = async function onCdnGeneratePresignUrl(params) {
    const [data, err] = await u.fetcher(F.CDN_GENERATE_PRESIGN_URL, params);
    if (err) return console.log(`onCdnGeneratePresignUrl:err`, err);
    return data;
  };

  /**
   *
   *
   *
   */
  return inner;
};


/**
 *
 *
 *
 */
export const Context = React.createContext({});


/**
 *
 *
 *
 */
export const Provider = (props) => {

  const currReducer = props.reducer ?? reducer;
  const currState = props.state ?? init;
  const [state, dispatch] = React.useReducer(currReducer, currState);
  const actions = getActions(state, dispatch);
  React.useState(() => { onIniStart(); }, []);

  return (
    <Context.Provider value={{ state, dispatch, actions }}>
      {props.children}
    </Context.Provider>
  );

  /**
   *
   *
   *
   */
  async function onIniStart() {
    await Promise.all([
      actions.onAuthGetMe(),
      actions.onAppGetSpecies(),
      actions.onAppGetSubmissions(),
      actions.onAppGetAnimals(),
    ]);

    dispatch({ type: 'INIT_END' });
  }
};
