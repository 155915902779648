/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import * as Store from '../store';


/**
 *
 *
 *
 */
const STEPS = [
  { $key: 'animal',  $s: 8, description: 'Animal Information', time: '1 min',   onValidate: s => !!s?.animal_id           },
  { $key: 'samples', $s: 9, description: 'Sample Information', time: '2 min',   onValidate: s => !!s?.samples?.length > 0 },
  { $key: 'form',    $s: 7, description: 'Review & Submit',    time: '1 min',   onValidate: s => s?.status === 'COMPLETE' },
  { $key: 'results', $s: 7, description: 'Results',            time: '~2 days', onValidate: s => s?.status === 'FINISHED' },
];


/**
 *
 *
 *
 */
export default function SubmissionSteps() {

  const nav = Router.useNavigate();
  const { id, step } = Router.useParams();
  const { state } = React.useContext(Store.Context);
  const currActiveItem = onCurrActiveItem();

  return (
    <S.Container>
      {STEPS.map(onStepComponent)}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onStepComponent(elm, idx) {

    const onClick = () => nav(`/submission/${id}/${idx}`);
    const $isLast = R.equals(idx, R.length(STEPS) - 1);
    const $isFirst = R.equals(idx, 0);
    const onSameC = (_, stepIdx) => idx === stepIdx;
    const onSameN = (_, stepIdx) => stepIdx === idx + 1;
    const $isComplete = STEPS.find(onSameC)?.onValidate(currActiveItem);
    const $isCompleteRight = STEPS.find(onSameN)?.onValidate(currActiveItem);
    const $isAnimal = R.equals(elm.$key, 'animal');
    const $isSamples = R.equals(elm.$key, 'samples');

    return (
      <S.StepContainer key={idx} $isLast={$isLast} $s={elm.$s} onClick={onClick}>
        <S.StepInnerContainer>
          <S.StepInfoContainer>
            <T.IO4 $c={'#6B7280'}>
              {elm.description}
            </T.IO4>
          </S.StepInfoContainer>
          <S.StepBottomContainer>
            <S.Line $isVisible={!$isFirst} $isComplete={$isComplete} />
            <S.StepCirlceContainer>
              <S.StepCirlce $isComplete={$isComplete}>
                <I.Tick stroke={'#FFF'} />
              </S.StepCirlce>
              <T.IM6 style={{ position: 'absolute', bottom: -20 }} $c={'#862e9c'}>
                {elm.time}
              </T.IM6>
            </S.StepCirlceContainer>
            <S.Line $isVisible={!$isLast} $isComplete={$isCompleteRight} />
          </S.StepBottomContainer>
        </S.StepInnerContainer>
      </S.StepContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(id);
    return state.submissions?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  flex: 1;
  display: flex;
  height: 120px;
  border-radius: 6px;
  border: 1px solid ${rgba('#862e9c', 0.1)};
`;

S.StepContainer = styled.div`
  flex: ${p => p.$s ?? 10};
  height: 100%;
  display: flex;
  padding: 15px 0px 30px 0px;
  &:hover {
    background: ${rgba('#862e9c', 0.05)};
    cursor: pointer;
  }
`;

S.StepInnerContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  align-content: start;
`;

S.StepInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  p { text-align: center; }
`;

S.StepBottomContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  bottom: 0px;
`;

S.StepCirlceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

S.StepCirlce = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid ${p => rgba('#862e9c', p.$isComplete ? 1 : 0.1)};
  background: ${p => rgba('#862e9c', p.$isComplete ? 1 : 0.1)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.Line = styled.div`
  flex: 1;
  height: 1px;
  ${p => p.$isVisible && `background: ${rgba('#862e9c', p.$isComplete ? 1 : 0.1)};`}
`;

S.SubInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 0px 10px;
`;

S.SubInfoRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

S.LineDivider = styled.div`
  flex: 1;
  margin: 0px 10px;
  height: 1px;
  background: rgba(134, 46, 156, 0.2);
`;