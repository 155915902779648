/**
 *
 *
 *
 */
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';


/**
 *
 *
 *
 */
const isDev = process.env.NODE_ENV === 'development';
const currPath = isDev ? `http://app.parvion.localhost:8045` : `https://app.parvion.uk`;


/**
 *
 *
 *
 */
const ADDRESS = [
  'Institut za patologijo, sodno in upravno veterinarstvo',
  'Gerbiceva 60',
  'SI 1000 Ljubljana',
  'TEL: +386 1 47 79 155',
  'FAX: +386 1 28 34 091',
];


/**
 *
 *
 *
 */
const qrParams = {
  margin: 0,
  width: 256,
  color: { dark: '#000000', light: '#FFFFFF' }
};


/**
 *
 *
 *
 */
const RATIO = 253 / 214;
const DIM = { W: 210, H: 297 };


/**
 *
 *
 *
 */
export const genReport = async (info) => {

  const doc = new jsPDF();
  const pW = doc.internal.pageSize.getWidth();
  const pH = doc.internal.pageSize.getHeight();
  const onLine = onLinePro(doc, pW);

  doc.setFont('helvetica');
  doc.setLineWidth(0.1);
  doc.setFontSize(16);
  doc.setFont(undefined, 'bold');
  doc.text('HISTO/CYTOLOGY REQUEST FORM', pW / 2, 15, { align: 'center' });

  onLine(20);

  doc.setFontSize(12);
  doc.setFont(undefined, 'normal');
  doc.text(ADDRESS, 10, 28);

  const qrW = 28;
  const qrH = 28;
  const qrX = pW - qrW - 10;
  const qrY = 25;
  const qrUrl = await QRCode.toDataURL(info?.hash ?? 'submissions:fyb4vac', qrParams);
  doc.addImage(qrUrl, 'PNG', qrX, qrY, qrW, qrH);

  onLine(58);

  await onAnimaleInfo(doc, info?.animal);
  await onPracticeInfo(doc, info?.practice);

  onLine(110);

  for (const [idx, sample] of info?.samples?.entries() ?? []) await onSample(doc, sample, idx);

  onLine(DIM.H - 16);
  await onFooter(doc);
  return doc;
}


/**
 *
 *
 *
 */
async function onAnimaleInfo(doc, animale) {

  const oX = 10;
  const oY = 65;
  const cW = 38;

  doc.setFontSize(14);
  doc.setFont(undefined, 'bold');
  doc.text('Animal', oX, oY);
  doc.setFontSize(12);
  doc.setFont(undefined, 'normal');

  const arrInfo = [
    { label: 'Name',        value: animale?.name || '' },
    { label: 'Owner',       value: animale?.owner || '' },
    { label: 'Gender',      value: animale?.gender || '' },
    { label: 'Species',     value: animale?.specie?.name || '' },
    { label: 'Breed',       value: animale?.breed?.name || '' },
    { label: 'DOB',         value: animale?.dob || '' },
    { label: 'Reference',   value: animale?.reference || '' },
  ];

  let cY = oY + 7;

  for (const { label, value } of arrInfo) {
    doc.text(`${label}:`, oX, cY, { align: 'left' });
    doc.text(value, cW, cY, { align: 'left' });
    cY += 5.6;
  }
}


/**
 *
 *
 *
 */
async function onPracticeInfo(doc, practice) {

  const oX = (DIM.W / 2) + 10;
  const oY = 65;
  const cW = (DIM.W / 2) + 35;

  doc.setFontSize(14);
  doc.setFont(undefined, 'bold');
  doc.text('Practice', oX, oY);
  doc.setFontSize(12);
  doc.setFont(undefined, 'normal');

  const arrInfo = [
    { label: 'Practice', value: practice?.name || '' },
    { label: 'City',     value: practice?.city || '' },
    { label: 'Address',  value: practice?.address || '' },
    { label: 'Postcode', value: practice?.postcode || '' },
  ];

  let cY = oY + 7;

  for (const { label, value } of arrInfo) {
    doc.text(`${label}:`, oX, cY, { align: 'left' });
    doc.text(value, cW, cY, { align: 'left' });
    cY += 5.6;
  }
}


/**
 *
 *
 *
 */
async function onSample(doc, sample, idx) {

  const offset = 114 + (idx * 50);
  doc.setFillColor(0, 0, 0);
  doc.roundedRect(10, offset, 128, 7, 0.5, 0.5, 'F');
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.text(`${sample?.type ?? ''} - ${sample?.method ?? ''}`, 12, offset + 5, { align: 'left' });
  doc.setTextColor(0, 0, 0);

  doc.setFontSize(12);
  doc.text('Location', 10, offset + 12, { align: 'left' });
  doc.setFontSize(10);
  doc.setTextColor(180, 180, 180);
  doc.text(sample?.location ?? '', 10, offset + 17, { align: 'left', maxWidth: 126 });
  doc.setTextColor(0, 0, 0);

  doc.setFontSize(12);
  doc.text('Description', 10, offset + 25, { align: 'left' });
  doc.setFontSize(10);
  doc.setTextColor(180, 180, 180);
  doc.text(sample?.description ?? '', 10, offset + 30, { align: 'left', maxWidth: 126 });
  doc.setTextColor(0, 0, 0);

  const png = await onSvg(sample.coordinates ?? {});
  const dims = [253 * 0.2, 214 * 0.2];
  doc.addImage(png, 'PNG', DIM.W - dims[0] - 12, offset, ...dims);
}


/**
 *
 *
 *
 */
async function onFooter(doc) {

  doc.setFontSize(12);
  doc.text('1/1', DIM.W / 2, DIM.H - 9, { align: 'center' });
}

/**
 *
 *
 *
 */
function onLinePro(doc, pW) {
  return (y) => {
    doc.setDrawColor(208, 215, 222);
    doc.line(10, y, pW - 10, y);
  }
}


/**
 *
 *
 *
 */
async function onSvg({x, y, w, h}) {

  const res = await fetch(`${currPath}/assets/anatomy.svg`);
  const txt = await res.text();

  const prs = new DOMParser();
  const dom = prs.parseFromString(txt, 'image/svg+xml');
  const svg = dom.querySelector('svg');
  w && svg.setAttribute('width', w);
  h && svg.setAttribute('height', h);

  const ns = 'http://www.w3.org/2000/svg';
  const grp = dom.createElementNS(ns, 'g');
  const circle = dom.createElementNS(ns, 'circle');
  x && circle.setAttribute('cx', x);
  y && circle.setAttribute('cy', y);
  circle.setAttribute('r', 12);
  circle.setAttribute('fill', '#000');
  circle.setAttribute('stroke', '#000');
  grp.appendChild(circle);
  svg.appendChild(grp);

  const srx = new XMLSerializer();
  const str = srx.serializeToString(svg);

  const canvas = document.createElement('canvas');
  canvas.width = w ?? 253;
  canvas.height = h ?? 214;
  const ctx = canvas.getContext('2d');

  const img = new Image();
  img.crossOrigin = 'anonymous';
  const blob = new Blob([str], { type: 'image/svg+xml;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  img.src = url;
  await onImageCarica(img);
  ctx.drawImage(img, 0, 0);
  URL.revokeObjectURL(url);
  const png = canvas.toDataURL('image/png');
  return png;
}


/**
 *
 *
 *
 */
function onImageCarica(img) {
  return new Promise(ok => {
    img.onload = () => ok();
  });
}
