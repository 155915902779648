/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import ButtonDanger from '../../_components/Button.Danger';
import ButtonWait from '../../_components/Button.Wait';
import Selector from '../../_components/Selector';
import Input from '../../_components/Input';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function SubmissionItem(props) {

  const nav = Router.useNavigate();
  const { state, actions } = React.useContext(Store.Context);
  const currPath = `/submission/${props.id}/0`;
  
  return (
    <S.Container>
      <S.ColumnContainer>
        <T.IL6>{props?.date}</T.IL6>
        <ButtonWait {...onViewProps()}>
          <T.IX6 $c={'#862e9c'}>View</T.IX6>
        </ButtonWait>
        <ButtonDanger {...onRemoveProps()}>
          <T.IX6 $c={'#fff'}>Delete</T.IX6>
        </ButtonDanger>
      </S.ColumnContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onViewProps() {
    return {
      $bg: rgba('#862e9c', 0.3),
      $br: '#862e9c',
      $tt: 200,
      $style: { minHeight: 40, maxHeight: 40 },
      onClick: () => nav(currPath),
    };
  }

  /**
   *
   *
   *
   */
  function onRemoveProps() {
    return {
      $br: '#F00',
      onClick: () => actions.onAppRemoveSubmission({
        id: props.id
      })
    };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
`;

S.ColumnContainer = styled.div`
  height: ${p => p.$h ?? '100%'};
  grid-column: span ${p => p.$s ?? 4};
  display: ${p => p.$d ?? 'grid'};
  grid-row-gap: 10px;
  align-content: start;
`;
