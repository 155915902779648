/**
 *
 *
 *
 */
export const AUTH_GET_ME = `
  query AuthGetMe {
    auth_get_me
  }
`;

/**
 *
 *
 *
 */
export const APP_GET_SPECIES = `
  query AppGetSpecies {
    app_get_species
  }
`;


/**
 *
 *
 *
 */
export const APP_GET_SUBMISSIONS = `
  query AppGetSubmissions {
    app_get_submissions
  }
`;


/**
 *
 *
 *
 */
export const APP_CREATE_SUBMISSION = `
  mutation AppCreateSubmission {
    app_create_submission
  }
`;


/**
 *
 *
 *
 */
export const APP_UPDATE_SUBMISSION = `
  mutation AppUpdateSubmission(
    $id: ID!,
    $animal_id: ID
  ) {
    app_update_submission(
      id: $id,
      animal_id: $animal_id
    )
  }
`;

/**
 *
 *
 *
 */
export const APP_REMOVE_ANIMAL_FROM_SUBMISSION = `
  mutation AppRemoveAnimalFromSubmission(
    $submission_id: ID!
  ) {
    app_remove_animal_from_submission(
      submission_id: $submission_id
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_REMOVE_SUBMISSION = `
  mutation AppRemoveSubmission(
    $id: ID!
  ) {
    app_remove_submission(
      id: $id
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_CONFIRM_SUBMISSION = `
  mutation AppConfirmSubmission(
    $id: ID!
  ) {
    app_confirm_submission(
      id: $id
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_GET_ANIMALS = `
  query AppGetAnimals {
    app_get_animals
  }
`;


/**
 *
 *
 *
 */
export const APP_CREATE_ANIMAL = `
  mutation AppCreateAnimal {
    app_create_animal
  }
`;


/**
 *
 *
 *
 */
export const APP_INSERT_ANIMAL = `
  mutation AppInsertAnimal(
    $specie: ID!,
    $breed: ID!,
    $name: String,
    $owner: String,
    $dob: String,
    $gender: String,
    $reference: String,
    $notes: String
  ) {
    app_insert_animal(
      specie: $specie,
      breed: $breed,
      name: $name,
      owner: $owner,
      dob: $dob,
      gender: $gender,
      reference: $reference,
      notes: $notes
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_UPDATE_ANIMAL = `
  mutation AppUpdateAnimal(
    $id: ID!,
    $specie: ID,
    $breed: ID,
    $name: String,
    $owner: String,
    $dob: String,
    $gender: String,
    $reference: String,
    $avatar: String
  ) {
    app_update_animal(
      id: $id,
      specie: $specie,
      breed: $breed,
      name: $name,
      owner: $owner,
      dob: $dob,
      gender: $gender,
      reference: $reference,
      avatar: $avatar
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_REMOVE_ANIMAL = `
  mutation AppRemoveAnimal(
    $id: ID!
  ) {
    app_remove_animal(
      id: $id
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_CREATE_SAMPLE = `
  mutation AppCreateSample(
    $submission_id: ID!
  ) {
    app_create_sample(
      submission_id: $submission_id
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_UPDATE_SAMPLE = `
  mutation AppUpdateSample(
    $id: ID!,
    $type: String,
    $method: String,
    $location: String,
    $description: String,
    $coordinates: String
  ) {
    app_update_sample(
      id: $id,
      type: $type,
      method: $method,
      location: $location,
      description: $description,
      coordinates: $coordinates
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_REMOVE_SAMPLE = `
  mutation AppRemoveSample(
    $id: ID!
  ) {
    app_remove_sample(
      id: $id
    )
  }
`;


/**
 *
 *
 *
 */
export const CDN_GENERATE_PRESIGN_URL = `
  mutation CdnGeneratePresignUrl(
    $bucket: String!,
    $name: String!,
    $ext: String!,
    $type: String!,
    $size: Int!
  ) {
    cdn_generate_presign_url(
      bucket: $bucket,
      name: $name,
      ext: $ext,
      type: $type,
      size: $size
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_CREATE_SAMPLE_MEDIA = `
  mutation AppCreateSampleMedia(
    $sample_id: ID!,
    $url: String!,
    $type: String!
  ) {
    app_create_sample_media(
      sample_id: $sample_id,
      url: $url,
      type: $type
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_REMOVE_SAMPLE_MEDIA = `
  mutation AppRemoveSampleMedia($id: ID!) {
    app_remove_sample_media(id: $id)
  }
`;
