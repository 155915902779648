/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as I from './Icons';


/**
 *
 *
 *
 */
Anatomy.defaultProps = {
  $w: 280,
  $h: 240,
};


/**
 *
 *
 *
 */
export default function Anatomy(props) {

  return (
    <S.Container $w={props.$w} $h={props.$h}>
      <I.Anatomy width={props.$w * 0.9} height={props.$h * 0.9} />
      <S.Svg onClick={onClick}>
        {props.$isSingle ? onPoint(props.coord) : props.coord?.map(onPoint)}
      </S.Svg>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onClick(evt) {
    evt.stopPropagation();
    const trg = evt.currentTarget.getBoundingClientRect();
    const x = evt.clientX - trg.left;
    const y = evt.clientY - trg.top;
    const w = props.$w;
    const h = props.$h;
    if (props.$isSingle) return props.onChange({ x, y, w, h });
    // setStore([...store, { x, y }]);
  }

  /**
   *
   *
   *
   */
  function onPoint(p, idx) {

    const subEvs = {
      onClick: onPointClick,
      onMouseEnter: onPointEnter,
      onMouseLeave: onPointLeave,
    };

    if (!p) return;

    return (
      <React.Fragment key={idx ?? '0'}>
        <S.Point cx={p.x} cy={p.y} />
        <S.Txt x={p.x} y={p.y + 1}>{(idx ? idx  + 1 : '')}</S.Txt>
        <S.Transparent cx={p.x} cy={p.y} {...subEvs} />
      </React.Fragment>
    );

    /**
     *
     */
    function onPointClick(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      console.log('onPointClick');
      // setCurrActiveIdx(idx);
    }

    /**
     *
     */
    function onPointEnter(evt) {
      console.log('onPointEnter');
    }

    /**
     *
     */
    function onPointLeave(evt) {
      console.log('onPointLeave');
    }
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.$w}px;
  height: ${p => p.$h}px;
  border-radius: 4px;
  border: 1px solid rgb(208, 215, 222);
`;

S.Svg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
`;

S.Point = styled.circle.attrs({
  r: 12,
})`
  fill: ${p => p.$isActive ? '#f00' : '#00f'};
  &:hover { cursor: pointer; fill: #ff8fab; }
`;

S.Txt = styled.text.attrs({
  textAnchor: 'middle',
  alignmentBaseline: 'middle',
})`
  fill: #FFF;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
`;

S.Transparent = styled.circle.attrs({
  r: 12,
})`
  fill: transparent;
  cursor: pointer;
`;
