/**
 *
 *
 *
 */
import React from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import Table from '../../_components/Table';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function AnimalsItemHistory(props) {

  const { state } = React.useContext(Store.Context);
  const submissions = onCurrSubmissions();

  return (
    <S.Container>
      <Table {...onTableProps()} />
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onTableProps() {
    return {
      // subFunc: onSubFunc,
      $theme: 'rgb(240, 220, 245)',
      data: submissions?.map(s => ({
        id: s.id,
        date: DateTime.fromISO(s.created_at).toFormat('dd.MM.yyyy'),
        animal: s.animal?.name ?? '-',
        owner: s.animal?.owner ?? '-',
        species: s.animal?.specie?.name ?? '-',
        breed: s.animal?.breed?.name ?? '-',
        vet: s.user?.first_name ?? '-',
        price: '€25',
        status: s.status,
      })),
    };
  }

  /**
   *
   *
   *
   */
  function onCurrSubmissions() {
    const onSame = s => String(s.animal_id) === String(props?.id);
    return state?.submissions?.filter(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
`;
