/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';
import * as Router from 'react-router-dom';
import Anatomy from '../../_components/Anatomy';
import Selector from '../../_components/Selector';
import MonacoEditor from '../../_components/MonacoEditor';
import ButtonDanger from '../../_components/Button.Danger';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import PlusCarica from './Plus.Carica';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
const SAMPLE_TYPE_OPTS = {
  'CYTOLOGY': ['FINE NEEDLE', 'FINE NEEDLE ASPIRATION', 'IMPRESSION SMEAR', 'BLOOD SMEAR', 'FLUID'],
  'HISTOLOGY': ['PUNCH BIOPSY', 'INCISIONAL BIOPSY', 'EXCISIONAL BIOPSY', 'AMPUTATED LIMB', 'ENTIRE ORGAN']
};


/**
 *
 *
 *
 */
const isDev = process.env.NODE_ENV === 'development';
const nex = isDev ? 'http://qr.parvion.localhost:8055' : 'https://qr.parvion.uk';


/**
 *
 *
 *
 */
export default function SubmissionSamplesItem(props) {

  const { id: submission_id } = Router.useParams();
  const { actions } = React.useContext(Store.Context);
  const [ isActiveIdx, setActiveIdx ] = React.useState();
  const onDebSave = React.useCallback(u.debounce(onSetupDebSave, 500), []);
  const $plusProps = { sample_id: props.id, submission_id, onDone: d => setActiveIdx(d.id) };
  const qrLink = `${nex}/${props.qr_code}`;

  return (
    <S.Container>
      <S.InnerInfoContainer>
        <S.SelectorsContainer>
          <Selector {...onTypeProps()} />
          <Selector {...onSubTypeProps()} />
          <ButtonDanger {...onRemoveProps()}>
            <I.Bin stroke={'#FFF'} style={{transform: 'scale(1.1)'}} />
          </ButtonDanger>
        </S.SelectorsContainer>
        <S.InfoColumnContainer>
          <S.InfoColumn $s={3}>
            <S.InfoColumnDesc>
              <T.IL6>Location</T.IL6>
              <T.ISX3>Specify the exact anatomical site of the sample.</T.ISX3>
              {/* <T.IM3 style={{ marginTop: 'auto' }}>(Auto save)</T.IM3> */}
            </S.InfoColumnDesc>
            <S.EditorContainer>
              <MonacoEditor key="location" {...onLocProps()} />
            </S.EditorContainer>
          </S.InfoColumn>
          <S.InfoColumn $s={5}>
            <S.InfoColumnDesc>
              <T.IL6>Description</T.IL6>
              <T.ISX3>Briefly describe the appearance or characteristics of the lesion or sample</T.ISX3>
              {/* <T.IM3 style={{ marginTop: 'auto' }}>(Auto save)</T.IM3> */}
            </S.InfoColumnDesc>
            <S.EditorContainer>
              <MonacoEditor key="description" {...onDescProps()} />
            </S.EditorContainer>
          </S.InfoColumn>
        </S.InfoColumnContainer>
      </S.InnerInfoContainer>
      <S.AnatomyContainer>
        <Anatomy {...onAnatomyChange()} />
        <S.MediaContainer $w={280} $h={250}>
          <S.MediaInnerContainer>
            {onCurrMediaElm()}
          </S.MediaInnerContainer>
          <S.MediaListaContainer>
            <PlusCarica {...$plusProps} />
            <S.MediaQrCode onClick={() => setActiveIdx('qr')}>
              {isActiveIdx === 'qr' && <S.DarkBack />}
              <I.QrCode />
            </S.MediaQrCode>
            {props.media?.map(onMediaImage)}
          </S.MediaListaContainer>
        </S.MediaContainer>
      </S.AnatomyContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onCurrMediaElm() {
    if (!isActiveIdx || isActiveIdx === 'qr') return onQrCodeElm();
    const onSame = elm => Number(elm.id) === Number(isActiveIdx);
    const media = props.media.find(onSame);
    return <S.MediaImage $isLarge={true} src={media.url} />;
  }

  /**
   *
   *
   *
   */
  function onQrCodeElm() {
    return (
      <React.Fragment>
        <S.Open onClick={() => window.open(qrLink, '_blank')} />
        <QRCodeSVG {...onQrCodeProps()} />
        <S.IM4>
          Scan to upload sample photos
          directly from the smartphone
        </S.IM4>
      </React.Fragment>
    );
  }

  /**
   *
   *
   *
   */
  function onAnatomyChange() {
    return {
      $w: 280,
      $h: 250,
      coord: props.coordinates,
      $isSingle: true,
      onChange: async (xyz) => await actions.onAppUpdateSample({
        id: props.id,
        coordinates: JSON.stringify(xyz)
      }),
    };
  }

  /**
   *
   *
   *
   */
  async function onSetupDebSave(params) {
    await actions.onAppUpdateSample(params);
  }

  /**
   *
   *
   *
   */
  function onRemoveProps() {
    return {
      $br: '#F00',
      $bg: ['rgba(255, 0, 0, 0.3)', '#D00'],
      $style: { width: 60 },
      onClick: onRemoveSample
    };
  }

  /**
   *
   *
   *
   */
  async function onRemoveSample() {
    await actions.onAppRemoveSample({ id: props.id });
  }

  /**
   *
   *
   *
   */
  function onQrCodeProps() {
    return {
      level: 'Q',
      value: qrLink,
      size: 100,
      bgColor: 'rgb(246, 248, 250)',
    };
  }

  /**
   *
   *
   *
   */
  function onTypeProps() {
    return {
      T: T.IL5,
      holder: 'Sample type',
      activeItem: props.type,
      opts: Object.keys(SAMPLE_TYPE_OPTS),
      $showLarge: true,
      $isRequire: true,
      onChange: async type => await actions.onAppUpdateSample({
        id: props.id,
        type
      }),
    };
  }

  /**
   *
   *
   *
   */
  function onSubTypeProps() {
    return {
      T: T.IL5,
      holder: 'Sampling method',
      activeItem: props.method,
      $showLarge: true,
      $isRequire: true,
      opts: SAMPLE_TYPE_OPTS[props.type] ?? [],
      onChange: async method => await actions.onAppUpdateSample({
        id: props.id,
        method
      }),
    };
  }

  /**
   *
   *
   *
   */
  function onLocProps() {
    return {
      value: props.location,
      language: 'text',
      onSave: async location => await onDebSave({
        id: props.id,
        location
      }),
      saveOnKeyStroke: true,
      lineNumbers: 'off',
      font: 10,
      info: ['Automatic save']
    };
  }

  /**
   *
   *
   *
   */
  function onDescProps() {
    return {
      value: props.description,
      language: 'text',
      onSave: async description => await onDebSave({ id: props.id, description }),
      saveOnKeyStroke: true,
      lineNumbers: 'off',
      font: 10,
      info: ['Automatic save']
    };
  }

  /**
   *
   *
   *
   */
  function onMediaImage(media) {

    const onMediaClick = () => setActiveIdx(media.id);
    const params = { submission_id, sample_id: props.id, media_id: media.id };
    const onRemove = () => actions.onAppRemoveSampleMedia(params);
    const $isActive = isActiveIdx === media.id;
    const $subProps = { $isActive, onClick: onMediaClick };

    return (
      <S.MediaImageContainer key={media.id} {...$subProps}>
        {$isActive && (
          <S.IconContainer>
            <I.Bin onClick={onRemove} />
          </S.IconContainer>
        )}
        <S.MediaImage src={media.url} />
      </S.MediaImageContainer>
    );
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
`;

S.SelectorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d0d7de;
  & > *:first-child { flex: 1; }
  & > *:nth-child(2) { flex: 2; }
`;

S.InnerInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

S.InfoColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 10px;
`;

S.InfoColumn = styled.div`
  flex: ${p => p.$s ?? 1};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

S.InfoColumnDesc = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

S.EditorContainer = styled.div`
  flex: 1;
`;

S.AnatomyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${p => p.$bg};
  background: ${p => rgba(p.$bg, 0.1)};
  width: 100%;
  height: 40px;
  &:hover {
    cursor: pointer;
    background: ${p => rgba(p.$bg, 0.8)};
    p { color: #fff; }
    svg { stroke: #fff; }
  }
`;

S.IM4 = styled(T.IM4)`
  padding: 0px 35px;
  text-align: center;
`;

S.Open = styled(I.Open)`
  position: absolute;
  top: 5px;
  right: 5px;
  transform: scale(1.1);
  &:hover { cursor: pointer; stroke: #862e9c; }
`;

S.MediaContainer = styled.div`
  flex: 1;
  width: ${p => p.$w}px;
  height: ${p => p.$h}px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

S.MediaInnerContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: rgb(246, 248, 250);
  border: 1px solid rgb(208, 215, 222);
  border-radius: 4px;
  position: relative;
`;

S.MediaListaContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  gap: 5px;
`;

S.MediaCreateContainer = styled.div`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgb(208, 215, 222);
  background: rgba(134, 46, 156, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  svg { stroke: #862e9c; }
  &:hover { cursor: pointer; background: rgba(134, 46, 156, 0.2); }
`;

S.MediaImageContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgb(208, 215, 222);
  background: rgba(134, 46, 156, 0.1);
  &:hover { cursor: pointer; opacity: 0.9; }
  ${p => p.$isActive && `border: 1px solid #862e9c;`}
`;

S.MediaImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  ${p => p.$isLarge && `
    height: 100%;
    width: 100%;
    object-fit: contain;
  `}
`;

S.MediaQrCode = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgb(208, 215, 222);
  background: #f6f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  svg { transform: scale(1.5); }
  &:hover { cursor: pointer; opacity: 0.8; }
`;

S.IconContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  svg {
    stroke: #fff;
    transform: scale(1.3);
    &:hover {
      cursor: pointer;
      stroke: #ff6600;
    }
  }
`;

S.DarkBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.5);
`;
