/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import SubmissionAnimaleLista from './Submission.Animale.Lista';
import SubmissionAnimaleForma from './Submission.Animale.Forma';
import SubmissionAnimaleUpdate from './Submission.Animale.Update';
import SubmissionAnimaleInfos from './Submission.Animale.Infos';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function SubmissionStepAnimal() {

  const { id } = Router.useParams();
  const { state } = React.useContext(Store.Context);
  const [ isUpdate, setIsUpdate ] = React.useState(false);
  const currActiveItem = onCurrActiveItem();
  const $isAnimale = !!currActiveItem?.animal;

  return (
    <S.Container>
      {onAnimaleInfo()}
      <SubmissionAnimaleLista />
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onAnimaleInfo() {
    if (isUpdate) return <SubmissionAnimaleUpdate />;
    if ($isAnimale) return <SubmissionAnimaleInfos onIsUpdate={setIsUpdate} />;
    return <SubmissionAnimaleForma />;
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(id);
    return state.submissions?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
