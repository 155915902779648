/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from '../../_components/Typography';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function Header() {

  const { state } = React.useContext(Store.Context);
  React.useEffect(() => { document.title = 'Parvion'; }, []);
  const name = `${state.me?.first_name} ${state.me?.last_name}`;
  const avatar = state.me?.avatar;
  const clinic = state.me?.clinic?.name;

  return (
    <S.Container id="header">
      <S.InnerContainer>
        <S.ItemLinkContainer href="/">
          <T.IO6>Parvion</T.IO6>
        </S.ItemLinkContainer>
        <S.ItemContainer style={{ marginLeft: 'auto', paddingRight: 0 }}>
          <S.ItemInnerContainer>
            <T.IL5 $c="#000">{name}</T.IL5>
            <T.IM4 $c="#6B7280">{clinic}</T.IM4>
          </S.ItemInnerContainer>
          <S.CircleContainer>
            <S.Img src={avatar} />
          </S.CircleContainer>
        </S.ItemContainer>
      </S.InnerContainer>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
var S = {};

S.Container = styled.div`
  top: 0px;
  position: fixed;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  background-color: white;
  @media print { display: none; }
  z-index: 10;
  @media (max-width: 1200px) {
    height: 50px;
    padding: 0px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  border-bottom: rgb(237, 239, 244);
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

S.ItemLinkContainer = styled.a`
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-decoration: none;
  :hover { cursor: pointer; }
`;

S.ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-decoration: none;
`;

S.ItemInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-right: 10px;
  text-decoration: none;
`;

S.CircleContainer = styled.div`
  flex-shrink: 0;
  background: rgb(246, 248, 250);
  border-radius: 50%;
  width: 35px;
  height: 35px;
`;

S.Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;
