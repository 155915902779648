/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { rgba } from 'polished';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import OmniSearch from '../../_components/Omni.Search';
import SubmissionItem from '../components/Submission.Item';
import Spinner from '../../_components/Spinner';
import Table from '../../_components/Table';
import * as I from '../../_components/Icons';
import Menu from '../components/Menu';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
export default function Submissions() {

  const nav = Router.useNavigate();
  const { pathname } = Router.useLocation();
  const [ isWait, setIsWait ] = React.useState(false);
  const { state, actions } = React.useContext(Store.Context);
  const $isActive = (pathname === '/submissions');

  return (
    <S.Container>
      <Menu />
      <S.InnerContainer>
        <S.HeaderContainer>
          <OmniSearch />
          <S.CreateContainer $bg={'#862e9c'} onClick={onCreateSubmission}>
            {isWait ? <Spinner $bg={'#F00'} /> : (
              <React.Fragment>
                <T.IL6 $c={'#862e9c'}>New Submission</T.IL6>
                <I.Plus style={{ transform: 'scale(1.2)' }} stroke={'#862e9c'} />
              </React.Fragment>
            )}
          </S.CreateContainer>
        </S.HeaderContainer>
        <Table {...onTableProps()} />
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onCreateSubmission() {
    if (isWait) return;
    setIsWait(true);
    await u.sleep(500);
    const data = await actions.onAppCreateSubmission();
    if (data) nav(`/submission/${data.id}/0`);
    setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onTableProps() {
    return {
      subFunc: onSubFunc,
      $theme: rgba('#862e9c', 0.1),
      data: state.submissions?.map(s => ({
        id: s.id,
        date: DateTime.fromISO(s.created_at).toFormat('dd.MM.yyyy'),
        animal: s.animal?.name ?? '-',
        owner: s.animal?.owner ?? '-',
        species: s.animal?.specie?.name ?? '-',
        breed: s.animal?.breed?.name ?? '-',
        vet: s.user?.first_name ?? '-',
        price: '€25',
        status: s.status,
      })),
    };
  }

  /**
   *
   *
   *
   */
  function onSubFunc(row) {

    const $isLast = R.equals(row.id, R.last(state?.submissions)?.id);
    const onSame = e => Number(e.id) === Number(row.id);
    const curr = state?.submissions?.find(onSame);

    return (
      <S.SubTableContainer key={row.id} $isLast={$isLast}>
        <SubmissionItem {...curr} />
      </S.SubTableContainer>
    );
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  height: 100%;
  display: flex;
  margin-top: 15px;
  @media (max-width: 1200px) {
    padding: 0px 10px 50px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
`;

S.HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  width: 100%;
  margin-bottom: 10px;
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 6px;
  border: 1px solid ${p => p.$bg};
  background: ${p => rgba(p.$bg, 0.1)};
  min-width: 200px;
  height: 100%;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
    background: ${p => rgba(p.$bg, 0.8)};
    p { color: #fff; }
    svg { stroke: #fff; }
  }
`;

S.SubTableContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-top: 1px solid #D0D7DE;
  ${p => !p.$isLast && `border-bottom: 1px solid #D0D7DE;`}
`;