/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import Selector from '../../_components/Selector';
import Spinner from '../../_components/Spinner';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import Input from '../../_components/Input';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
export default function SubmissionAnimalForma() {

  const { id } = Router.useParams();
  const nav = Router.useNavigate();
  const [ isWait, setIsWait ] = React.useState(false);
  const { state, actions } = React.useContext(Store.Context);
  const currActiveItem = onCurrActiveItem();
  const currAnimale = currActiveItem?.animal;
  const isSame = s => String(s.id) === String(currAnimale?.specie?.id);
  const currSpecie = state?.species?.find(isSame);

  return (
    <S.Container>
      <S.DescInner>
        <S.DescText>
          <T.IX5 $c={'#000'}>Animal</T.IX5>
          <T.IM3>Add a new animal to the submission</T.IM3>
        </S.DescText>
        <S.DescIcon>
          <I.Doc />
        </S.DescIcon>
      </S.DescInner>
      <S.InnerContainer>
        {onForma()}
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onForma() {

    if (!state.init) return <Spinner />;

    return (
      <React.Fragment>
        <S.InnerColumn>
          <Selector {...onSpecieProps()} />
          <Selector {...onBreedProps()} />
          <Selector {...onGenderProps()} />
          <Input {...onChipProps()} />
        </S.InnerColumn>
        <S.InnerColumn>
          <Input {...onReferenceProps()} />
          <Input {...onNameProps()} />
          <Input {...onOwnerProps()} />
          <Input {...onDobProps()} />
        </S.InnerColumn>
      </React.Fragment>
    );
  }

  /**
   *
   *
   *
   */
  function onSpecieProps() {
    return {
      T: T.IL5,
      holder: 'Species',
      activeItem: currAnimale?.specie?.name,
      opts: R.pluck('name', state.species || []),
      onChange: async name => {
        const specie = state.species.find(s => s.name === name);
        await actions.onAppUpdateAnimal({ id, specie: specie.id });
      }
    };
  }

  /**
   *
   *
   *
   */
  function onBreedProps() {
    return {
      T: T.IL5,
      holder: 'Breed',
      activeItem: currAnimale?.breed?.name,
      opts: R.pluck('name', currSpecie?.breeds || []),
      onChange: async name => {
        const breed = currSpecie?.breeds.find(b => b.name === name);
        await actions.onAppUpdateAnimal({ id, breed: breed.id });
      }
    };
  }

  /**
   *
   *
   *
   */
  function onGenderProps() {
    return {
      T: T.IL5,
      activeItem: currAnimale?.gender,
      holder: 'Gender',
      onChange: async gender => await actions.onAppUpdateAnimal({ id, gender }),
      opts: ['MALE', 'FEMALE'],
    };
  }

  /**
   *
   *
   *
   */
  function onChipProps() {
    return {
      placeholder: 'Chip number',
      value: currAnimale?.chip ?? '',
      onSave: async chip => await actions.onAppUpdateAnimal({ id, chip })
    };
  }

  /**
   *
   *
   *
   */
  function onNameProps() {
    return {
      placeholder: 'Animal name',
      value: currAnimale?.name ?? '',
      onSave: async name => await actions.onAppUpdateAnimal({ id, name })
    };
  }

  /**
   *
   *
   *
   */
  function onOwnerProps() {
    return {
      placeholder: 'Owner surname',
      value: currAnimale?.owner ?? '',
      onSave: async owner => await actions.onAppUpdateAnimal({ id, owner })
    };
  }

  /**
   *
   *
   *
   */
  function onDobProps() {
    return {
      placeholder: 'Date of Birth',
      value: currAnimale?.dob ?? '',
      onSave: async dob => await actions.onAppUpdateAnimal({ id, dob })
    };
  }

  /**
   *
   *
   *
   */
  function onReferenceProps() {
    return {
      placeholder: 'Clinical internal id',
      value: currAnimale?.reference ?? '',
      onSave: async reference => await actions.onAppUpdateAnimal({ id, reference })
    };
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(id);
    return state.submissions?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 10px;
`;

S.DescInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

S.DescText = styled.div`
  display: flex;
  flex-direction: column;
`;

S.DescIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d7de;
  border-radius: 4px;
  padding: 4px;
  svg {
    width: 32px;
    height: 32px;
    stroke: #d0d7de;
    stroke-width: 0.6;
  }
`;

S.InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 15px;
  border-bottom: 1px solid #d0d7de;
  gap: 10px;
`;

S.InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

S.Create = styled.div`
  margin-top: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5c940d;
  background: ${rgba('#5c940d', 0.1)};
  border-radius: 4px;
  p { color: #5c940d; }
  &:hover { cursor: pointer; }
  ${p => !p.$isComplete && `
    background: ${rgba('#d0d7de', 0.8)};
    border: 1px solid #d0d7de;
    p { color: #999; }
    &:hover { cursor: not-allowed; }
  `}
`;
