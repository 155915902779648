/**
 *
 *
 *
 */
import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import * as I from '../../_components/Icons';
import Spinner from '../../_components/Spinner';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
export default function PlusCarica(props) {

  const currFileRef = React.useRef(null);
  const { state, actions } = React.useContext(Store.Context);
  const [ isWait, setIsWait ] = React.useState(false);
  const { sample_id, submission_id } = props;

  return (
    <S.Container onClick={() => currFileRef?.current?.click()}>
      {isWait ? (
        <S.SpinnerContainer>
          <Spinner $bg={'#000'} />
        </S.SpinnerContainer>
      ) : (
        <I.Plus style={{ transform: 'scale(1.2)' }} />
      )}
      <S.FileHide {...onFileProps()} />
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onFileProps() {
    return {
      onChange: onFileChange,
      ref: currFileRef,
      type: 'file',
      multiple: false
    };
  }

  /**
   *
   *
   *
   */
  async function onFileChange(evt) {
    setIsWait(true);
    evt.preventDefault();
    evt.stopPropagation();
    const currFile = evt.target.files[0];
    const { type, size } = currFile;
    const name = u.genStr();
    const ext = currFile.type.split('/').pop();
    const params = { bucket: 'assets', name, ext, type, size };
    const data = await actions.onCdnGeneratePresignUrl(params);
    if (!data) return setIsWait(false);
    const headers = { 'Content-Type': currFile.type };
    const config = { headers, onUploadProgress };
    await axios.put(data.url, currFile, config);
    const nex = `https://cdn.parvion.uk/assets/${name}.${ext}`;
    const args = { sample_id, submission_id, url: nex, type };
    const payload = await actions.onAppCreateSampleMedia(args);
    props.onDone(payload);
    return setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onUploadProgress(evt) {
    const { loaded, total } = evt;
    const prct = Math.round((loaded * 100) / total);
    console.log(`onUploadProgress:prct`, prct);
    // return setProgress(prct);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgb(208, 215, 222);
  background: rgba(134, 46, 156, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  svg { stroke: #862e9c; }
  &:hover { cursor: pointer; background: rgba(134, 46, 156, 0.2); }
`;

S.FileHide = styled.input`
  visibility: hidden;
  position: absolute;
  outline: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

S.SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div { right: 4px; }
`;
