/**
 *
 *
 *
 */
import React from 'react';
import * as Router from 'react-router-dom';
import styled from 'styled-components';
import { GlobeStyle } from '../_components/Style';
import Analytics from '../_components/Analytics';
import NotFound from '../_components/NotFound';
import Header from './components/Header';
import Submissions from './pages/Submissions';
import Dashboard from './pages/Dashboard';
import Submission from './pages/Submission';
import Animals from './pages/Animals';
import Animal from './pages/Animal';
import * as Store from './store';


/**
 *
 *
 *
 */
export default function App() {

  return (
    <Router.BrowserRouter basename="/">
      <Store.Provider>
        <GlobeStyle />
        <Header />
        <Analytics />
        <S.PageContainer>
          <Router.Routes>
            <Router.Route path='/'                     element={<Dashboard />} />
            <Router.Route path='/dashboard'            element={<Dashboard />} />
            <Router.Route path='/submissions'          element={<Submissions />} />
            <Router.Route path='/animals'              element={<Animals />} />
            <Router.Route path='/animal/:id'           element={<Animal />} />
            <Router.Route path='/submission/:id'       element={<Submission />} />
            <Router.Route path='/submission/:id/:step' element={<Submission />} />
            <Router.Route path="*"                     element={<NotFound />} />
          </Router.Routes>
        </S.PageContainer>
      </Store.Provider>
    </Router.BrowserRouter>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1200px;
  padding-top: 50px;
  padding-bottom: 10px;
  margin: auto;
  height: 100vh;
  @media (max-width: 600px) {
    padding-top: 50px;
  }
`;
