/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';


/**
 *
 *
 *
 */
export function Horse(props) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" stroke="black" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M49.5 17V18.5" />
      <path d="M44 15.5L42.5 16.5L41 18.5L40 20L39.5 20.5L38 21H36H34H32.5M44 15.5H45L45.5 16L46 15.5L46.5 14.5L47 12.5V12M44 15.5V14L44.5 13M47 12L47.5 11L48 10.5H49L49.5 11.5V13V14V14.5L50.5 15L51.5 16L52 17V17.5V19L52.5 19.5L53 20.5L53.5 21L54.5 22L55 22.5V24L54.5 25H53H52L51 24L49.5 23.5L48.5 23L47.5 22.5M47 12L46.5 10L45 12L44.5 13M47.5 22.5L47 21V20M47.5 22.5L46.5 23.5L46 24.5L45 27L44.5 29L43 31.5M43 31.5L41.5 33L40 35L39.5 37L39 39.5V42V45.5V46L40 47L41 48M43 31.5V31.75L42.5 33.5L42 35L41.5 36.5V43.5V45L43 47L44.5 48M41 48V48.5V49.5M41 48H37M41 48H44.5M41 49.5H38.5L38 49L37 48M41 49.5H44.5V48M37 48L36.5 47V46.5V41.5V37.5V36V35L37 34.5V33.5V31.75M37 30V31.75M37 31.75L35 34L34 34.5L33.5 35H32H29.5H28L27 34.5L26 34L25 33L24.5 32.5M24.5 32.5L26.5 30M24.5 32.5L23 34.5L22.5 36L22 37.5V39.5V43.5V44.5L22.5 45.5L23.5 46L24 46.5L25 47.5L25.5 48M25.5 48V49.5H22.5M25.5 48H21.5M22.5 49.5L21.5 48M22.5 49.5H19.282L18.3205 48M21.5 48L20.5 47L20 45.5V44L19.5 42L19 40.5L18.5 39L19.5 37V35V33.5L19.282 31.75L18.891 30.875M21.5 48H18.3205M17 27L17.5 28L18.5 30L18.891 30.875M17 27V25L17.5 23L19 21L19.75 20.5M17 27L16 27.5L15 29V33L14.5 34H13.5L13 33.5V30V28.5L14 27V25.5V22.5L15 21L16 20L17.5 19.5H18.5L19.75 20.5M18.891 30.875L18 33.5L17 36L16.5 38V39.5V41V42.5L17 44.5L17.5 46L18 47.5L18.3205 48M44.5 13L41.5 14L40.5 15.5L39.5 16.5H38.5L37.5 17.5L36.5 18H35.5H33.5L32.5 19V20V21M32.5 21H31.5L29 20.5L27 20H25.5H22.5H20.5L19.75 20.5" />
    </svg>
  );
}


/**
 *
 *
 *
 */
export function Dog(props) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="black" {...props}>
      <path d="M38.4432 20.9286L37.9091 21.4571L35.7727 22.5143L34.1705 23.5714L32.5682 24.1H30.4318H28.2955L25.625 23.5714H24.0227H20.8182H18.6818L17.0795 24.1L16.0114 24.6286L14.9432 25.6745L13.875 27.2264L13.3409 28.7784V29.3857M38.4432 20.9286L38.9773 18.8143L39.5114 17.2286L40.5795 15.6429L41.1136 14.5857L42.7159 13.5286L43.7841 13H45.3864H46.4545L47.5227 13.5286L48.5909 14.0571L49.6591 15.1143L50.7273 16.1714L51.7955 17.2286L52.8636 17.7571H53.3977L54.4659 18.2857H55V18.8143V21.4571L54.4659 21.9857L53.3977 23.0429H52.3295H50.7273H47.5227H46.9886H45.9205L45.3864 24.1L44.8523 25.1571L44.3182 26.2143V31.5L43.7841 33.0857L41.6477 36.7857L40.5795 38.9L40.0455 40.4857V42.6V45.2429L40.5795 46.8286L41.1136 47.3571L42.1818 47.8857L43.25 48.4143V48.9429V50H38.4432H36.8409V48.9429L36.3068 47.8857V45.7714V36.7857V36.2571H31.5L29.3636 35.7286L27.7614 35.2L26.1591 34.6714H25.0909L23.4886 35.2L22.6942 36.5383L21.3523 37.8429L20.2841 39.4286L19.2159 39.9571L18.1477 40.4857V41.5429V45.2429V46.8286L19.2159 47.3571L20.2841 47.8857L21.3523 48.4143L20.8182 50H18.1477H15.4773H14.4091L13.875 49.4714V36.5383V34.469L13.3409 33.4343V31.8824V29.3857M38.4432 20.9286L38.9773 22.5143L40.0455 23.0429L41.1136 23.5714H41.6477L42.7159 23.0429L43.25 22.5143L44.3182 21.4571L44.8523 20.9286V19.8714V17.7571V16.7V16.1714M48.0568 16.7H47.5227H46.9886M13.3409 29.3857H11.7386L10.1364 28.8571L9.06818 27.8L8 26.7429V25.6857V24.6286" />
    </svg>
  );
}


/**
 *
 *
 *
 */
export function Cat(props) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="black" {...props}>
      <path d="M38.4359 23.7746V20.0282L39.4872 18.4225L44.2179 13.6056L45.7949 12V14.1408V16.8169L47.3718 17.8873L47.8974 19.493L48.9487 20.0282L49.4744 21.0986L50 22.7042L49.4744 23.7746L48.4231 24.3099L46.8462 24.8451H45.2692V25.9155V26.4507M38.4359 23.7746L37.3846 24.8451L34.2308 25.3803L31.6026 26.4507L30.0256 27.5211L27.9231 29.662L26.3462 31.2676L25.2949 33.9437L24.2436 36.6197V39.2958V41.4366L24.7692 44.6479L25.8205 47.3239M38.4359 23.7746L40.0128 24.8451L42.1154 25.9155L44.2179 26.4507H45.2692M41.0641 43.5775L41.5897 44.6479V45.7183L42.1154 46.7887V48.3944V48.9296L42.641 50H45.7949H46.8462L47.8974 49.4648V48.3944V47.8592L47.3718 47.3239H46.3205L44.7436 46.7887L44.2179 46.2535V40.3662L44.7436 39.2958L45.2692 38.2254L45.7949 37.1549L46.8462 36.0845L47.3718 33.9437V31.2676L46.8462 29.662L45.7949 28.5915L45.2692 26.9859V26.4507M41.0641 43.5775V37.1549M41.0641 43.5775L38.9615 45.7183L37.9103 46.7887M37.9103 46.7887L38.4359 48.3944L38.9615 49.4648L36.859 50H34.7564H26.3462M37.9103 46.7887H35.2821M26.3462 50H22.141L20.0385 48.9296L18.4615 47.8592L17.4103 46.7887L16.8846 45.1831V43.5775L17.4103 40.9014L18.4615 38.7606L18.9872 37.1549V34.4789L18.4615 32.8732L16.8846 31.2676L15.8333 30.7324H14.2564L12.6795 31.2676L11.6282 32.338V34.4789V36.0845L12.1538 37.1549L12.6795 38.2254L12.1538 39.2958L11.6282 39.831L10.5769 39.2958L10.0513 38.2254L9.52564 36.6197L9 35.0141V31.2676L10.0513 30.1972L11.1026 29.1268L12.6795 28.5915L14.2564 28.0563H15.3077H16.359L17.4103 28.5915L18.9872 29.662L20.0385 31.2676L21.0897 32.338L21.6154 33.9437V37.6901V39.2958L21.0897 40.3662L20.5641 41.9718L20.0385 43.0423V45.1831L20.5641 46.2535L21.6154 46.7887L23.1923 47.3239H24.7692H25.8205M26.3462 50L25.8205 47.3239M45.2692 18.4225V20.0282M32.6538 46.7887H35.2821M35.2821 46.7887L36.859 44.1127L37.9103 42.507V40.3662L37.3846 38.7606L36.3333 37.6901L34.7564 36.6197H32.6538" />
    </svg>
  );
}


/**
 *
 *
 *
 */
export function Rabbit(props) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" stroke="black" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M48 24V25.5" />
      <path d="M14.4 41.7125V36.8375L15.3778 34.4L16.8444 31.475L18.3111 29.0375L20.2667 27.0875L22.7111 25.625L25.6444 24.65L28.0889 24.1625H31.5111L34.4444 24.65L36.8889 25.625L38.8444 26.6L39.8222 27.575L40.8 25.625L41.7778 24.1625L42.2667 23.675M14.4 41.7125L15.3778 43.6625L16.8444 45.6125M14.4 41.7125L12.4444 42.2L11.4667 42.6875L10.4889 43.6625L10 44.6375V45.6125L10.9778 46.1L12.9333 46.5875H15.3778L16.8444 45.6125M43.7333 22.2125L42.7556 23.1875L42.2667 23.675M43.7333 22.2125H45.6889H47.1556M43.7333 22.2125V19.2875L43.2444 17.825L42.2667 15.3875M47.1556 22.2125H48.1333L50.0889 23.675L51.5556 25.1375L52.5333 27.0875L53.5111 28.55L54 29.525V30.9875L53.0222 32.45L51.5556 32.9375H49.6H46.6667H45.2V36.8375V38.7875L44.2222 41.225L42.7556 43.175L41.2889 44.6375L40.8 45.125M47.1556 22.2125V20.75L48.1333 18.3125V16.3625L47.6444 14.4125L45.6889 11.975L44.7111 11H43.7333L43.2444 11.975L42.2667 14.4125V15.3875M40.8 45.125L42.7556 47.075L44.2222 48.5375V49.5125V50H41.7778H39.8222L38.3556 48.5375L36.4 46.5875L35.9111 46.1M40.8 45.125L39.3333 42.6875L38.8444 41.225M35.9111 46.1V42.2M35.9111 46.1L33.4667 46.5875H32.4889M24.6667 36.8375H27.1111H28.5778L29.5556 38.3L30.5333 39.7625V41.225V42.2L29.0667 44.6375V46.1L31.5111 46.5875H32.4889M32.4889 46.5875L33.9556 48.5375V50H28.5778H24.6667L24.1778 48.5375L23.6889 47.075M23.6889 47.075L22.7111 45.125L21.7333 43.6625L19.7778 42.6875M23.6889 47.075L22.7111 47.5625H20.7556L18.8 47.075L16.8444 45.6125M42.2667 15.3875L39.8222 13.4375L38.3556 12.4625L37.3778 13.4375V15.875V18.3125L38.8444 20.2625L40.8 22.2125L42.2667 23.675" />
    </svg>
  );
}


/**
 *
 *
 *
 */
export function Bird(props) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="black" {...props}>
      <path d="M16.5 42.5L17 41.5L18 40L25.5 33.5L29 30L32 27L33 25L34 23L34.5 21.5L36 19L37 17.5L38.5 16H40L42.5 17L43.5 17.5L44 20L44.5 21H46L47 21.5L48 22V22.5L47.5 23L46.5 23.5H44.5V24V25.5L45.5 27.5L46.5 30.5V32L46 34.5L45.5 36L43 39L39 42L35.5 43.5H32H23L20.5 44L18 45L15.5 46.5L14 47.5L13 48L12.5 48.5H12H11.5L11 48V47.5V46.5L12 45.5L14.5 44L16.5 42.5ZM16.5 42.5H17.5L19.5 42L25 39.5L30.5 37.5L33.5 36.5L36.5 35.5L40.5 33.5L42.5 32L43 31V28.5V27.5L42 26.5L41 26M41.5 19V20.5" />
      <path d="M33 43.5L32.5 45.5L32 48.5L31.5 50M31.5 50H29M31.5 50H33.5M35 43.5L34 47.5L33.5 49.5V50M33.5 50H36" />
    </svg>
  );
}
