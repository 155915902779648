/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import MonacoEditor from '../../_components/MonacoEditor';
import Menu from '../components/Menu';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function Dashboard() {

  const { pathname } = Router.useLocation();
  const { state } = React.useContext(Store.Context);
  const $isActive = pathname === '/dashboard';

  return (
    <S.Container>
      <Menu />
      <S.InnerContainer>
        <MonacoEditor key={state.me?.email} {...onMonacoProps()} />
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onMonacoProps() {
    return {
      language: 'json',
      value: JSON.stringify(state.me, null, 2),
      readOnly: true,
    };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  height: 100%;
  display: flex;
  margin-top: 15px;
  @media (max-width: 1200px) {
    padding: 0px 10px 50px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 100%;
`;