/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function SubmissionStepResult() {

  const { state } = React.useContext(Store.Context);

  return (
    <S.Container>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div``;
