/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import T from '../../_components/Typography';
import * as A from '../../_components/Animals';
import Selector from '../../_components/Selector';
import Input from '../../_components/Input';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function AnimalsItemOverview(props) {

  const id = props.id;
  const { state, actions } = React.useContext(Store.Context);
  const currAnimale = onCurrActiveItem();
  const currSpecie = onCurrSpecie();

  return (
    <S.Container>
      <S.GridaContainer>
        <S.IconContainer>
          {onActiveIconComp()}
        </S.IconContainer>
        <S.InnerColumn>
          <Selector {...onSpecieProps()} />
          <Selector {...onBreedProps()} />
          <Selector {...onGenderProps()} />
          <Input {...onChipProps()} />
        </S.InnerColumn>
        <S.InnerColumn>
          <Input {...onReferenceProps()} />
          <Input {...onNameProps()} />
          <Input {...onOwnerProps()} />
          <Input {...onDobProps()} />
        </S.InnerColumn>
      </S.GridaContainer>
    </S.Container>
  );


  /**
   *
   *
   *
   */
  function onActiveIconComp() {
    switch (currAnimale?.specie?.name) {
      case 'Dog': return <A.Dog />;
      case 'Cat': return <A.Cat />;
      case 'Rabbit': return <A.Rabbit />;
      case 'Bird': return <A.Bird />;
      case 'Horse': return <A.Horse />;
      default: return null;
    }
  }


  /**
   *
   *
   *
   */
  function onSpecieProps() {
    return {
      T: T.IL5,
      holder: 'Species',
      activeItem: currAnimale?.specie?.name,
      opts: R.pluck('name', state.species || []),
      $showLarge: true,
      $isRequire: true,
      onChange: async name => {
        const specie = state.species.find(s => s.name === name);
        await actions.onAppUpdateAnimal({ id, specie: specie.id });
      }
    };
  }

  /**
   *
   *
   *
   */
  function onBreedProps() {
    return {
      T: T.IL5,
      holder: 'Breed',
      activeItem: currAnimale?.breed?.name,
      opts: R.pluck('name', currSpecie?.breeds || []),
      $showLarge: true,
      $isRequire: true,
      onChange: async name => {
        const breed = currSpecie?.breeds.find(b => b.name === name);
        await actions.onAppUpdateAnimal({ id, breed: breed.id });
      }
    };
  }

  /**
   *
   *
   *
   */
  function onGenderProps() {
    return {
      T: T.IL5,
      activeItem: currAnimale?.gender,
      holder: 'Gender',
      $showLarge: true,
      $isRequire: true,
      onChange: async gender => await actions.onAppUpdateAnimal({ id, gender }),
      opts: ['MALE', 'FEMALE'],
    };
  }

  /**
   *
   *
   *
   */
  function onChipProps() {
    return {
      placeholder: 'Chip number',
      value: currAnimale?.chip ?? '',
      onSave: async chip => await actions.onAppUpdateAnimal({ id, chip }),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onNameProps() {
    return {
      placeholder: 'Animal name',
      value: currAnimale?.name ?? '',
      onSave: async name => await actions.onAppUpdateAnimal({ id, name }),
      $showLarge: true,
      $isRequire: true,
    };
  }

  /**
   *
   *
   *
   */
  function onOwnerProps() {
    return {
      placeholder: 'Owner surname',
      value: currAnimale?.owner ?? '',
      onSave: async owner => await actions.onAppUpdateAnimal({ id, owner }),
      $showLarge: true,
      $isRequire: true,
    };
  }

  /**
   *
   *
   *
   */
  function onDobProps() {
    return {
      $type: 'date',
      placeholder: 'Date of Birth',
      value: currAnimale?.dob ?? '',
      onSave: async dob => await actions.onAppUpdateAnimal({ id, dob }),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onReferenceProps() {
    return {
      placeholder: 'Clinical internal id',
      value: currAnimale?.reference ?? '',
      onSave: async reference => await actions.onAppUpdateAnimal({ id, reference }),
      // $popInfo: 'This is the internal id of the animal in the clinic',
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = a => String(a.id) === String(props.id);
    return state?.animals?.find(onSame);
  }

  /**
   *
   *
   *
   */
  function onCurrSpecie() {
    const onSame = s => String(s.id) === String(currAnimale?.specie?.id);
    return state?.species?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
`;

S.GridaContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 10px;
`;

S.InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

S.IconContainer = styled.div`
  width: 180px;
  height: 100%;
  border-radius: 6px;
  background: #f6f8fa;
  border: 1px solid #d0d7de;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 102, 0, 0.1);
  svg { stroke: rgb(255, 102, 0); transform: scale(2.8); }
`;