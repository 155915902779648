/**
 *
 *
 *
 */
import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';


/**
 *
 *
 *
 */
export default function Menu() {

  const nav = Router.useNavigate();
  const { pathname } = Router.useLocation();
  const isSubmission = pathname.indexOf('/submission') !== -1;
  const isAnimale = pathname.indexOf('/animal') !== -1;

  return (
    <S.Container>
      <S.MenuItem {...onDashSubProps()}>
        <I.Table {...onDashProps()} />
        <T.IM5 $c={pathname === '/dashboard' ? '#FFF' : '#1864ab'}>
          Dashboard
        </T.IM5>
      </S.MenuItem>
      <S.MenuItem {...onDocSubProps()}>
        <I.Doc {...onDocProps()} />
        <T.IM5 $c={isSubmission ? '#FFF' : '#862e9c'}>
          Submissions
        </T.IM5>
      </S.MenuItem>
      <S.MenuItem {...onPetSubProps()}>
        <I.Pet {...onPetProps()} />
        <T.IM5 $c={isAnimale ? '#FFF' : '#5c940d'}>
          Animals
        </T.IM5>
      </S.MenuItem>
      <S.MenuItem {...onYopSubProps()} />
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onYopSubProps() {
    return {
      $bg: 'rgb(246, 248, 250)',
      style: {
        flex: 1,
        border: '1px solid rgb(208, 215, 222)',
        background: 'rgb(246, 248, 250)',
      }
    };
  }

  /**
   *
   *
   *
   */
  function onDashProps() {
    return {
      stroke: pathname === '/dashboard' ? '#FFF' : '#1864ab',
      strokeWidth: '1.0',
      width: '40px',
      height: '40px',
    };
  }

  /**
   *
   *
   *
   */
  function onDashSubProps() {
    return {
      $bg: '#228be6',
      onClick: () => nav('/dashboard'),
      $isActive: pathname === '/dashboard',
    };
  }

  /**
   *
   *
   *
   */
  function onDocProps() {
    return {
      stroke: isSubmission ? '#FFF' : '#862e9c',
      strokeWidth: '0.7',
      width: '40px',
      height: '40px',
    };
  }

  /**
   *
   *
   *
   */
  function onDocSubProps() {
    return {
      $bg: '#862e9c',
      onClick: () => nav('/submissions'),
      $isActive: isSubmission,
    };
  }

  /**
   *
   *
   *
   */
  function onPetProps() {
    return {
      stroke: isAnimale ? '#FFF' : '#5c940d',
      strokeWidth: '0.1',
      width: '90px',
      height: '90px',
    };
  }

  /**
   *
   *
   *
   */
  function onPetSubProps() {
    return {
      $bg: '#5c940d',
      onClick: () => nav('/animals'),
      $isActive: isAnimale,
    };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;

S.MenuItem = styled.div`
  width: 90px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${p => rgba(p.$bg, 0.1)};
  margin-bottom: 10px;
  border-radius: 6px;
  &:last-child { margin-bottom: 0; }
  &:hover { cursor: pointer; background-color: ${p => rgba(p.$bg, 0.3)}; }
  ${p => p.$isActive && `background-color: ${rgba(p.$bg, 1)};`}
`;
