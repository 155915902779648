/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import ButtonWait from '../../_components/Button.Wait';
import * as A from '../../_components/Animals';
import T from '../../_components/Typography';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
const COLUMNS = [
  [
    { $label: 'Name',    $key: 'name'                                              },
    { $label: 'Owner',   $key: 'owner'                                             },
    { $label: 'Species', $key: 'specie.name', onKey: e => e['specie']?.name ?? '-' },
  ],
  [
    { $label: 'Breed',   $key: 'breed.name',  onKey: e => e['breed']?.name ?? '-'  },
    { $label: 'Age',     $key: 'age',         onKey: e => u.onAge(e['dob'])        },
    { $label: 'Gender',  $key: 'gender',      onKey: e => e['gender'] ?? '-'       },
  ]
];


/**
 *
 *
 *
 */
export default function SubmissionAnimalInfos(props) {

  const { id } = Router.useParams();
  const { state, actions } = React.useContext(Store.Context);
  const currActiveItem = onCurrActiveItem();
  if (!currActiveItem) return null;
  const elm = currActiveItem?.animal ?? {};

  return (
    <S.Container>
      <S.DescInner>
        <S.DescText>
          <T.IM3>Add a new animal to the submission</T.IM3>
        </S.DescText>
      </S.DescInner>
      <S.InnerContainer>
        <S.IconContainer>
          {onActiveIconComp()}
        </S.IconContainer>
        {COLUMNS.map((rows, cIdx) => (
          <S.InnerColumn key={`col-${cIdx}`}>
            {rows.map(onRowComp)}
          </S.InnerColumn>
        ))}
      </S.InnerContainer>
      <S.OptionContainer>
        <ButtonWait {...onBtnUpdateProps()}>
          <T.IL5 $c={'rgb(24, 100, 171)'}>Update Animal Info</T.IL5>
        </ButtonWait>
        <ButtonWait {...onBtnRemoveProps()}>
          <T.IL5 $c={'rgb(255, 48, 48)'}>Remove Patient</T.IL5>
        </ButtonWait>
      </S.OptionContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onBtnUpdateProps() {
    return {
      $bg: 'rgb(204, 229, 255)',
      $br: 'rgb(24, 100, 171)',
      $style: { minHeight: '40px', maxHeight: '40px' },
      // onClick: () => props.onIsUpdate(true),
    };
  }

  /**
   *
   *
   *
   */
  function onBtnRemoveProps() {
    return {
      $bg: 'rgb(255, 204, 204)',
      $br: 'rgb(255, 48, 48)',
      $style: { minHeight: '40px', maxHeight: '40px' },
      onClick: onRemove,
    };
  }

  /**
   *
   *
   *
   */
  async function onRemove() {
    const params = { submission_id: id };
    await actions.onAppRemoveAnimalFromSubmission(params);
  }

  /**
   *
   *
   *
   */
  function onActiveIconComp() {
    switch (elm?.specie?.name) {
      case 'Dog': return <A.Dog />;
      case 'Cat': return <A.Cat />;
      case 'Rabbit': return <A.Rabbit />;
      case 'Bird': return <A.Bird />;
      case 'Horse': return <A.Horse />;
      default: return null;
    }
  }

  /**
   *
   *
   *
   */
  function onRowComp(r, rIdx) {
    return (
      <S.RowInfoContainer key={`row-${rIdx}`}>
        <T.IL5>{r.$label}:</T.IL5>
        <S.LineDivider />
        <T.IL3>{r.onKey ? r.onKey(elm) : elm[r.$key] ?? '-'}</T.IL3>
      </S.RowInfoContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(id);
    return state.submissions?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 10px;
`;

S.DescInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

S.DescText = styled.div`
  display: flex;
  flex-direction: column;
`;

S.DescIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d7de;
  border-radius: 4px;
  padding: 4px;
  svg {
    width: 32px;
    height: 32px;
    stroke: #d0d7de;
    stroke-width: 0.6;
  }
`;

S.InnerContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  padding-bottom: 15px;
  border-bottom: 1px solid #d0d7de;
  gap: 20px;
`;

S.InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: 10px;
`;

S.RowInfoContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

S.LineDivider = styled.div`
  flex: 1;
  margin: 0px 10px;
  height: 1px;
  background-color: rgba(134, 46, 156, 0.2);
  background-color: rgb(255, 102, 0);
`;

S.IconContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 6px;
  background: #f6f8fa;
  border: 1px solid #d0d7de;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 102, 0, 0.1);
  svg {
    stroke: rgb(255, 102, 0);
    transform: scale(2);
  }
`;

S.OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  gap: 10px;
`;

