/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import Selector from '../../_components/Selector';
import T from '../../_components/Typography';
import Input from '../../_components/Input';
import Menu from '../components/Menu';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function Animal() {

  const nav = Router.useNavigate();
  const { id } = Router.useParams();
  const { state, actions } = React.useContext(Store.Context);
  const currAnimale = onCurrActiveItem();
  const isSame = s => String(s.id) === String(currAnimale?.specie?.id);
  const currSpecie = state?.species?.find(isSame);

  return (
    <S.Container>
      <Menu />
      <S.InnerContainer>
        <S.GridaContainer>
          <S.InnerColumn>
            <Selector {...onSpecieProps()} />
            <Selector {...onBreedProps()} />
            <Selector {...onGenderProps()} />
            <Input {...onChipProps()} />
          </S.InnerColumn>
          <S.InnerColumn>
            <Input {...onReferenceProps()} />
            <Input {...onNameProps()} />
            <Input {...onOwnerProps()} />
            <Input {...onDobProps()} />
          </S.InnerColumn>
        </S.GridaContainer>
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onSpecieProps() {
    return {
      T: T.IL5,
      holder: 'Species',
      activeItem: currAnimale?.specie?.name,
      opts: R.pluck('name', state.species || []),
      $showLarge: true,
      $isRequire: true,
      onChange: async name => {
        const specie = state.species.find(s => s.name === name);
        await actions.onAppUpdateAnimal({ id, specie: specie.id });
      }
    };
  }

  /**
   *
   *
   *
   */
  function onBreedProps() {
    return {
      T: T.IL5,
      holder: 'Breed',
      activeItem: currAnimale?.breed?.name,
      opts: R.pluck('name', currSpecie?.breeds || []),
      $showLarge: true,
      $isRequire: true,
      onChange: async name => {
        const breed = currSpecie?.breeds.find(b => b.name === name);
        await actions.onAppUpdateAnimal({ id, breed: breed.id });
      }
    };
  }

  /**
   *
   *
   *
   */
  function onGenderProps() {
    return {
      T: T.IL5,
      activeItem: currAnimale?.gender,
      holder: 'Gender',
      $showLarge: true,
      $isRequire: true,
      onChange: async gender => await actions.onAppUpdateAnimal({ id, gender }),
      opts: ['MALE', 'FEMALE'],
    };
  }

  /**
   *
   *
   *
   */
  function onChipProps() {
    return {
      placeholder: 'Chip number',
      value: currAnimale?.chip ?? '',
      onSave: async chip => await actions.onAppUpdateAnimal({ id, chip }),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onNameProps() {
    return {
      placeholder: 'Animal name',
      value: currAnimale?.name ?? '',
      onSave: async name => await actions.onAppUpdateAnimal({ id, name }),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onOwnerProps() {
    return {
      placeholder: 'Owner surname',
      value: currAnimale?.owner ?? '',
      onSave: async owner => await actions.onAppUpdateAnimal({ id, owner }),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onDobProps() {
    return {
      $type: 'date',
      placeholder: 'Date of Birth',
      value: currAnimale?.dob ?? '',
      onSave: async dob => await actions.onAppUpdateAnimal({ id, dob }),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onReferenceProps() {
    return {
      placeholder: 'Clinical internal id',
      value: currAnimale?.reference ?? '',
      onSave: async reference => await actions.onAppUpdateAnimal({ id, reference }),
      $showLarge: true,
      $popInfo: 'This is the internal id of the animal in the clinic',
    };
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = a => String(a.id) === String(id);
    return state?.animals?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  margin-top: 15px;
  @media (max-width: 1200px) {
    padding: 0px 10px 50px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
`;

S.GridaContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

S.InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;