/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import AnimalsItemOverview from './Animals.Item.Overview';
import AnimalsItemHistory from './Animals.Item.History';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import * as Store from '../store';


/**
 *
 *
 *
 */
const NAVBAR = [
  { label: 'Overview', icon: p => <I.Doc style={{ transform: 'scale(1.1)' }} {...p} /> },
  { label: 'History',  icon: p => <I.Clock style={{ transform: 'scale(0.9)' }} {...p} /> },
];


/**
 *
 *
 *
 */
export default function AnimalsItem(props) {

  const [ idxActive, setIdxActive ] = React.useState(0);

  return (
    <S.Container>
      <S.NavContainer>
        {NAVBAR.map(onNavItem)}
      </S.NavContainer>
      {onActiveComponent()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onActiveComponent() {
    if (idxActive === 0) return <AnimalsItemOverview id={props.id} />;
    if (idxActive === 1) return <AnimalsItemHistory id={props.id} />;
  }

  /**
   *
   *
   *
   */
  function onNavItem(item, idx) {

    const CurrIcon = item.icon;
    const $isActive = (idx === idxActive);
    const onClick = () => setIdxActive(idx);

    return (
      <S.NavItemContainer key={idx} $isActive={$isActive} onClick={onClick}>
        <CurrIcon />
      </S.NavItemContainer>
    );
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: row;
`;

S.NavContainer = styled.div`
  flex-shrink: 0;
  width: 50px;
  border-right: 1px solid rgb(208, 215, 222);
  padding-right: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

S.NavItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgb(208, 215, 222);
  svg { stroke: rgb(208, 215, 222); }
  &:hover {
    cursor: pointer;
    border: 1px solid #e67700;
    background-color: rgba(230, 119, 0, 0.1);
    // ${S.TipContainer} { display: block; }
    svg { stroke: #e67700; }
  }
  ${p => p.$isActive && `
    border: 1px solid #1864ab;
    background-color: rgba(34, 139, 230, 0.1);
    svg { stroke: #1864ab; }
  `}
`;


S.ColumnContainer = styled.div`
  height: ${p => p.$h ?? '100%'};
  grid-column: span ${p => p.$s ?? 4};
  display: ${p => p.$d ?? 'grid'};
  grid-row-gap: 10px;
  align-content: start;
`;
