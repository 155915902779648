/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import SampleItem from './Submission.Samples.Item';
import Spinner from '../../_components/Spinner';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
export default function SubmissionStepSamples() {

  const { id } = Router.useParams();
  const { state, actions } = React.useContext(Store.Context);
  const [ isWait, setIsWait ] = React.useState(false);
  const currActiveItem = onCurrActiveItem();

  return (
    <S.Container>
      {currActiveItem?.samples?.map(onSampleComponent)}
      {onCreateComponent()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onCreateComponent() {
    return (
      <S.CreateContainer $bg={'#fd8c73'} onClick={onCreateSample}>
        {isWait ? <Spinner $bg={'#fd8c73'} /> : (
          <React.Fragment>
            <I.Plus style={{ transform: 'scale(1.1)', marginRight: 10 }} stroke={'#fd8c73'} />
            <T.IL6 $c={'#fd8c73'}>Add sample</T.IL6>
          </React.Fragment>
        )}
      </S.CreateContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(id);
    return state.submissions.find(onSame);
  }

  /**
   *
   *
   *
   */
  async function onCreateSample() {
    if (isWait) return;
    setIsWait(true);
    const submission_id = currActiveItem.id;
    await actions.onAppCreateSample({ submission_id });
    return setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onSampleComponent(elm, idx) {

    return (
      <React.Fragment key={elm.id}>
        {idx > 0 && <S.LineDiv />}
        <SampleItem {...elm} />
      </React.Fragment>
    );
  }
}



/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding: 0px 15px;
  // margin-right: 15px;
  // border-right: 1px solid rgba(134, 46, 156, 0.1);
  gap: 10px;
`;

S.InnerRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
`;

S.SelectorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  & > *:first-child { flex: 1; }
  & > *:nth-child(2) { flex: 2; }
`;

S.InnerInfoContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

S.InfoColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  gap: 10px;
`;

S.InfoColumn = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

S.AnatomyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

S.MediaContainer = styled.div`
  flex: 1;
  width: ${p => p.$w}px;
  height: ${p => p.$h}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(246, 248, 250);
  border: 1px solid rgb(208, 215, 222);
  border-radius: 4px;
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${p => p.$bg};
  background: ${p => rgba(p.$bg, 0.1)};
  width: 100%;
  height: 40px;
  &:hover {
    cursor: pointer;
    background: ${p => rgba(p.$bg, 0.8)};
    p { color: #fff; }
    svg { stroke: #fff; }
  }
`;

S.IM4 = styled(T.IM4)`
  margin-top: 5px;
  padding: 0px 25px;
  text-align: center;
`;

S.LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #d0d7de;
  margin: 5px 0px;
`;
