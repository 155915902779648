/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import Selector from '../../_components/Selector';
import Spinner from '../../_components/Spinner';
import T from '../../_components/Typography';
import Input from '../../_components/Input';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
const TXT = `Add a new animal to the submission. Required fields are marked with an asterisk (*)`;


/**
 *
 *
 *
 */
export default function SubmissionAnimalForma() {

  const { id } = Router.useParams();
  const [ isWait, setIsWait ] = React.useState(false);
  const { state, actions } = React.useContext(Store.Context);
  const [ currSpecie, setCurrSpecie ] = React.useState(null);
  const [ currBreed, setCurrBreed ] = React.useState(null);
  const [ currGender, setCurrGender ] = React.useState(null);
  const [ currName, setCurrName ] = React.useState(null);
  const [ currOwner, setCurrOwner ] = React.useState(null);
  const [ currDob, setCurrDob ] = React.useState(null);
  const [ currReference, setCurrReference ] = React.useState(null);
  const [ currChip, setCurrChip ] = React.useState(null);
  const $isComplete = onCheckComplete();

  return (
    <S.Container>
      <S.DescInner>
        <S.DescText>
          <T.IM3>{TXT}</T.IM3>
        </S.DescText>
      </S.DescInner>
      <S.InnerContainer>
        <S.InnerColumn>
          <Selector {...onSpecieProps()} />
          <Selector {...onBreedProps()} />
          <Selector {...onGenderProps()} />
          <Input {...onChipProps()} />
        </S.InnerColumn>
        <S.InnerColumn>
          <Input {...onReferenceProps()} />
          <Input {...onNameProps()} />
          <Input {...onOwnerProps()} />
          <Input {...onDobProps()} />
        </S.InnerColumn>
      </S.InnerContainer>
      <S.Create $isComplete={$isComplete} onClick={onCreateAnimale}>
        {isWait ? <Spinner $bg={'#F00'} /> : <T.IX4>Create new record</T.IX4>}
      </S.Create>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onCheckComplete() {
    return currSpecie && currBreed && currGender && currName && currOwner;
  }

  /**
   *
   *
   *
   */
  async function onCreateAnimale() {
    if (isWait || !$isComplete) return;
    setIsWait(true);
    await u.sleep(100);
    const params = { specie: currSpecie.id, breed: currBreed.id, name: currName, owner: currOwner, dob: currDob, gender: currGender, reference: currReference };
    const data = await actions.onAppInsertAnimal(params);
    await actions.onAppUpdateSubmission({ id, animal_id: data.id });
    return setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onSpecieProps() {
    return {
      T: T.IL5,
      holder: 'Species',
      activeItem: currSpecie?.name,
      opts: R.pluck('name', state.species || []),
      $showLarge: true,
      $isRequire: true,
      onChange: async name => {
        const onSame = elm => elm.name === name;
        const specie = state.species?.find(onSame);
        return setCurrSpecie(specie);
      }
    };
  }

  /**
   *
   *
   *
   */
  function onBreedProps() {
    return {
      T: T.IL5,
      holder: 'Breed',
      activeItem: currBreed?.name,
      opts: R.pluck('name', currSpecie?.breeds || []),
      $showLarge: true,
      $isRequire: true,
      onChange: async name => {
        const onSame = elm => elm.name === name;
        const breed = currSpecie?.breeds?.find(onSame);
        return setCurrBreed(breed);
      }
    };
  }

  /**
   *
   *
   *
   */
  function onGenderProps() {
    return {
      T: T.IL5,
      activeItem: currGender,
      holder: 'Gender',
      onChange: async gender => setCurrGender(gender),
      opts: ['MALE', 'FEMALE'],
      $showLarge: true,
      $isRequire: true,
    };
  }

  /**
   *
   *
   *
   */
  function onChipProps() {
    return {
      placeholder: 'Chip number',
      value: currChip,
      onChange: async chip => setCurrChip(chip),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onNameProps() {
    return {
      placeholder: 'Animal name',
      $isRequire: true,
      value: currName,
      onChange: async name => setCurrName(name),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onOwnerProps() {
    return {
      placeholder: 'Owner surname',
      $isRequire: true,
      value: currOwner,
      onChange: async owner => setCurrOwner(owner),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onDobProps() {
    return {
      $type: 'date',
      placeholder: 'Date of Birth',
      value: currDob,
      onChange: async dob => setCurrDob(dob),
      $showLarge: true,
    };
  }

  /**
   *
   *
   *
   */
  function onReferenceProps() {
    return {
      placeholder: 'Clinical internal id',
      value: currReference,
      onChange: async reference => setCurrReference(reference),
      $showLarge: true,
      $popInfo: 'This is the internal id of the animal in the clinic',
    };
  }

  /**
   *
   *
   *
   */
  function onCurrActiveItem() {
    const onSame = elm => Number(elm.id) === Number(id);
    return state.submissions?.find(onSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 10px;
`;

S.DescInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
`;

S.DescText = styled.div`
  display: flex;
  flex-direction: column;
`;

S.DescIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d7de;
  border-radius: 4px;
  padding: 4px;
  svg {
    width: 32px;
    height: 32px;
    stroke: #d0d7de;
    stroke-width: 0.6;
  }
`;

S.InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #d0d7de;
  border-top: 1px solid #d0d7de;
  gap: 10px;
`;

S.InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

S.Create = styled.div`
  margin-top: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5c940d;
  background: ${rgba('#5c940d', 0.1)};
  border-radius: 4px;
  p { color: #5c940d; }
  &:hover { cursor: pointer; }
  ${p => !p.$isComplete && `
    background: ${rgba('#d0d7de', 0.8)};
    border: 1px solid #d0d7de;
    p { color: #999; }
    &:hover { cursor: not-allowed; }
  `}
`;
